<script>
  import { createEventDispatcher } from "svelte";
  import axios from "axios";
  import { getLocalStorage, setLocalStorage } from "../utils/localStorage";
  import TelField from './TelField.svelte';

  const dispatch = createEventDispatcher();

  export let person;
  export let bgcolor;
  export let data;

  const personId = `${person.id}leaveMessage`;
  const personForm = getLocalStorage(personId);

  let num = personForm && personForm.num ? personForm.num : null;
  let code =  data.dialingCode;
  let showSuccess = false;
  let showFail = false;
  let err = false;

  function send() {
    if (!num) {
      err = "You number is necessary";
      return;
    } else if (num.toString().length < 6) {
      err = "You must have a number here";
      return;
    } else {
      err = false;
    }

    let fullNumber = code.toString()+num.toString();

    axios
      .post("process.env.MY_HOST" + "/api/callback", {
        id: person.id,
        number: fullNumber,
      })
      .then((response) => {
        showSuccess = true;
        showFail = false;
      })
      .catch((error) => {
        console.log(error);
        showSuccess = false;
        showFail = true;
      });
  }

  const setVal = (v) => {
    code = v.detail.code.trim();
    num = v.detail.num.trim();
  };
</script>

<div class="leave-message">
  <p style="font-size: 14px">
    {#if data.offlineMessage }
      {data.offlineMessage}
      {:else}
      This operator is not online at the moment. Please leave your telephone
      number and we'll get back
    {/if}
  </p>

  {#if showSuccess}
    <div class="success" style="font-size: 14px">
      Your message has been created
    </div>
  {/if}

  {#if showFail}
    <div class="fail" style="font-size: 14px">
      There has been an error, Please contact support
    </div>
  {/if}  

  <TelField {data} on:message={setVal} class="whataapp-telfield"/>
  {#if err}<div style="color:darkred;font-size:14px">{err}</div>{/if}

  <button
    on:click={() => send()}
    style="background:{bgcolor};color:#fff"
    class="button">

    {#if (data.leaveMessage)}
      {data.leaveMessage}
    {:else}
      Leave a message
    {/if}
  </button
  >
</div>

<style>

  .leave-message{
    background: white;
  }

  .button {
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;
    border: none;
  }

  .success {
    padding: 10px;
    background: darkgreen;
    color: white;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .fail {
    padding: 10px;
    background: darkred;
    color: white;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  :global(.whataapp-telfield){
    margin-bottom:20px;
  }
</style>
