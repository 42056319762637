<script>
  export let person;
  export let isVertical;
</script>

<div class="chatwith-person">
  <div
    class="chatwith-singleperson {person.online === true
      ? 'chatwith-active'
      : 'chatwith-inactive'} {isVertical === true
      ? ''
      : 'chatwith-singleperson-horizontal'}"
  >
    <div class="chatwith-avatar">
      <img
        class="chatwith-avatar-pic"
        alt=""
        src={person.iconUrl}
        onerror="this.src='process.env.MY_HOST'+'/bk.png'"
      />
      <span class="chatwith-circle" />
    </div>
    <div class="chatwith-persondata {isVertical === true
      ? 'chatwith-persondata-vertical'
      : 'chatwith-persondata-horizontal'}">
       {#if person.post && person.post != "null" && person.post != null}
        <div class="chatwith-post">{person.post}</div>
       {/if}
      <div class="{isVertical === true
        ? 'chatwith-name-vertical'
        : 'chatwith-name'}">{person.name}</div>
      <div class="chatwith-status chatwith-online">
        <span class="chatwith-circle" />
        {#if person.online === true} Online{:else} Offline{/if}
      </div>
    </div>
  </div>
</div>

<style>
  .chatwith-person .chatwith-post {
    font-size: 12px;
    color: #5c5b5b;
    line-height: 1.1em;
  }

  .chatwith-person .chatwith-singleperson {
    color: #5c5b5b;
  }
  .chatwith-person .chatwith-singleperson-horizontal {
    display: flex;
  }

  .chatwith-person .chatwith-avatar {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .chatwith-person .chatwith-persondata {
    flex: 3;
  }
  .chatwith-person .chatwith-persondata-vertical {
    text-align: center;
  }
  .chatwith-person .chatwith-persondata-horizontal {
    text-align: left;
  }

  .chatwith-person .chatwith-avatar-pic {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin: auto;
    display: block;
  }

  .chatwith-person .chatwith-name {
    font-weight: bold;
    font-size: 16px;
    padding: 5px 0;
    line-height: 1.1em;
  }

  .chatwith-person .chatwith-name-vertical {
    font-weight: bold;
    font-size: 13px;
    padding: 5px 0;
    line-height: 1.1em;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chatwith-person .chatwith-status {
    font-size: 11px;
    color: #ccc;
  }

  .chatwith-person .chatwith-circle {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    display: inline-block;
    background: #ccc;
  }

  .chatwith-person .chatwith-active .chatwith-status {
    color: green;
  }

  .chatwith-person .chatwith-active .chatwith-circle {
    background: green;
  }

  .chatwith-person .chatwith-active {
    cursor: pointer;
    line-height: 1.1em;
  }
  .chatwith-person .chatwith-avatar .chatwith-circle {
    display: none;
  }

  @media (max-width: 767px) {
    .chatwith-person .chatwith-singleperson {
      align-items: center;
    }

    .chatwith-person .chatwith-avatar {
      display: block;
      flex: 0.5;
      margin: 0 1em 0 0.5em;
      position: relative;
    }

    .chatwith-person .chatwith-avatar-pic {
      width: 36px;
      height: 36px;
    }

    .chatwith-person .chatwith-post {
      font-size: 11px;
    }

    .chatwith-person .chatwith-name {
      font-weight: bold;
      font-size: 14px;
      padding: 3px 0;
      line-height: 1.1em;
    }

    /* Show Online status in responsive */
    .chatwith-status {
      display: block;
    }
    /* Hide green dot in responsive */
    .chatwith-person .chatwith-avatar .chatwith-circle {
      display: none;
      position: absolute;
      right: 0;
      top: 25px;
      border: 2px solid #fff;
    }
    .chatwith-person .chatwith-inactive .chatwith-avatar .chatwith-avatar-pic {
      filter: grayscale(100%);
    }
  }
</style>
