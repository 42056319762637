<div class="{person.online === true?'chatwith-active':'chatwith-inactive'}">
    <div class="chatwith-status chatwith-online">
        <span class="chatwith-circle"></span>
        {#if person.online === true} Online{:else} Offline{/if}
    </div>
</div>

<script>
    export let person;
</script>

<style>
    .chatwith-status {
        font-size: .65em;
        position: absolute;
        top: 74px;
        right: -9px;
        display: flex;
        align-items: center;
    }

    .chatwith-active .chatwith-status{
        color: #2acc04;
    }

    .chatwith-active .chatwith-circle{
        background: #2acc04;
    }

    .chatwith-circle {
        width: 20px;
        height: 20px;
        border: 4px solid #fff;
        border-radius: 20px;
        display: inline-block;
        background: #ccc;
    }

</style>
