
function showOnlyOnce(data, widgetType){
    if(data.openOnce === true && data.turnOffCookies !== true && widgetType == 'classic'){
        if(document.cookie.indexOf('wWidgetOnlyOnce') == -1 ){
            document.cookie = 'wWidgetOnlyOnce=true'
        }
        else{
            return false
        }
    }
    else if(widgetType == 'classic'){
        document.cookie = "wWidgetOnlyOnce=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    return true
}

export { showOnlyOnce };
