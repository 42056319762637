<script>
    import { onDestroy } from 'svelte';

    export let data;
  
    let now = Date.now();
    let end = new Date(data.countDownEnds);    

    $: count = Math.round((end - now) / 1000);

    $: if (count <= 0) 
    {
        count = 0;
        clearInterval(interval); 
    }

    $: d = count?Math.floor(count / 3600 /24):0;
    $: h = count?Math.floor((count - d * 3600 * 24)/3600):0;
    $: m = count?Math.floor((count - d * 3600 * 24 - h * 3600) / 60):0;
    $: s = count?(count - d * 3600 * 24 - h * 3600 - m * 60):0;
  
    function updateTimer() {
      now = Date.now();
    }
  
    let interval = setInterval(updateTimer, 1000);  
  
    function padValue(value, length = 2, char = '0') {
      if(value > 99) return 99;
      const { length: currentLength } = value.toString();
      if (currentLength >= length) return value.toString();
      return `${char.repeat(length - currentLength)}${value}`;
    }
  
    onDestroy(() => {
      clearInterval(interval);
    });
</script>
<div class = "chatwith-countdown">
    <div class = "chatwith-countdown-box">
        <span  style="color:{data.color};font-family:{data.countDownFont}">{padValue(d)}</span>
        <p>DAYS</p>
    </div>
    <div class = "chatwith-countdown-box">
        <span  style="color:{data.color};font-family:{data.countDownFont}">{padValue(h)}</span>
        <p>HOURS</p>
    </div>
    <div class = "chatwith-countdown-box">
        <span  style="color:{data.color};font-family:{data.countDownFont}">{padValue(m)}</span>
        <p>MINUTES</p>
    </div>
    <div class = "chatwith-countdown-box">
        <span  style="color:{data.color};font-family:{data.countDownFont}">{padValue(s)}</span>
        <p>SECONDS</p>
    </div>
</div>

<style>
.chatwith-countdown{
    width: 100%;
    background-color: #000031;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 5px;
}
.chatwith-countdown-box{  
    width:52px;
    background-color: #34335b;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 10px 10px 0px;
    border-radius: 10%;
}
.chatwith-countdown span{    
    /* color: #db555a; */
    font-size: 20pt;
    font-weight: 900;
}
.chatwith-countdown p{    
    color: #6c6b79;
    font-size: 6pt;
}
</style>
  