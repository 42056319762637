<script>
    import {createEventDispatcher} from "svelte";
    import Dots from "./Dots.svelte";

    export let bubbleData

    const dispatch = createEventDispatcher();

</script>

<div  class="whataapp-chat-bubble-user-wrapper">
    <div  class="whataapp-chat-bubble-user">
        {bubbleData.val}
    </div>

</div>

<style>

    .whataapp-chat-bubble-user-wrapper{
        text-align: right;
    }

    /* .whataapp-chat-bubble-user{
        display: inline-block;
        background: #edf2f4;
        border-radius: 10px 10px 0  10px ;
        padding:10px 10px;
        margin:0 5px 5px 5px;
        font-size: 14px;
        text-align: right;
    } */

    .whataapp-chat-bubble-user{
        display: inline-block;
        background: #e6e6e6;
        border-radius: 18px 0 18px 18px;
        padding:10px 10px;
        margin:0 5px 5px 5px;
        font-size: 14px;
        text-align: right;
    }

</style>
