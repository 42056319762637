<script>
    import {createEventDispatcher} from "svelte";
    import Dots from "./Dots.svelte";

    export let text
    const dispatch = createEventDispatcher();

    async function wait() {
        await new Promise(resolve => setTimeout(resolve, 2000))
        return true;
    }
</script>

<div class="whataapp-chat-bubble-bot-wrapper">
    <div class="whataapp-chat-bubble-bot">
        {#await wait()}
            <Dots/>
        {:then v}
            <div> {text}</div>
        {/await}

    </div>

</div>

<style>

    /* .whataapp-chat-bubble-bot {
        display: inline-block;
        background: #cccccc;
        border-radius: 10px 10px 10px 0;
        padding: 10px 10px;
        margin: 0 5px 5px 5px;
        font-size: 14px;
    } */

    .whataapp-chat-bubble-bot {
        display: inline-block;
        padding: 10px 10px;
        margin: 0 5px 5px 5px;
        font-size: 14px;
        background-color: rgb(91, 160, 208);
        border-radius: 0 18px 18px 18px;
        color: white;
    }


</style>
