<script>

    import { fade } from 'svelte/transition';
    import { onMount } from 'svelte';
    import {isReturnUser} from "../utils/localStorage";

    export let data;
    export let message;

    let show = false;

    onMount(() => {
        if (isReturnUser()){
            if (!sessionStorage.getItem('tochatsess')) {
                sessionStorage.setItem('tochatsess', "true");
                show = true;
            }
            setTimeout(() => (show = false), 3000);
        }
    });

</script>

{#if show}
<div
        style="background:{data.color}"
        class="welcome-back"
        in:fade="{{ delay:2000 }}" out:fade="{{ delay:4000 }}">


    {#if message}
        {message}
    {:else }
        <p>👋 Hello, we're glad your're back!</p>
        <p>If you have any questions, we would be happy to help.</p>
    {/if}
</div>
{/if}


<style>

    .welcome-back {
        box-sizing: border-box;
        min-width: 200px;
        max-width: 260px;
        padding: 10px 12px;
        margin: 10px;
        border-radius: 6px;
        background-color: white;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
        text-align: center;
        font-size: .9rem;
        color: black;
    }

</style>
