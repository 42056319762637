<script>
    export let data;
    export let isReturnUser;  
</script>

{#if isReturnUser}
    {#if data.returningBannerUrl !== null && data.activateReturningBanner === true}
        {#if data.returningBannerLink !== null}
            <a class="chatwith-banner-link" href={data.returningBannerLink} target="_blank">
            <img class="chatwith-banner" alt="Banner" src={data.returningBannerUrl} />
            </a>
        {:else}
            <img
            class="chatwith-banner"
            alt="Banner"
            title="No available link"
            src={data.returningBannerUrl}
            />
        {/if}
    {/if}
{:else}
    {#if data.bannerUrl !== null && data.ActivateBanner === true}
        {#if data.bannerLink !== null}
            <a class="chatwith-banner-link" href={data.bannerLink} target="_blank">
            <img class="chatwith-banner" alt="Banner" src={data.bannerUrl} />
            </a>
        {:else}
            <img
            class="chatwith-banner"
            alt="Banner"
            title="No available link"
            src={data.bannerUrl}
            />
        {/if}
    {/if}
{/if}
<style>
 .chatwith-banner-link {
    display: block;
    text-decoration: none;
    cursor: pointer;
  }

  .chatwith-banner {
    height: auto;
    width: 100%;
    margin: auto;
    display: block;
  }
</style>