<script>
  import { countries } from "../utils/countries";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let data;
  export let showButton;
  export let value = "";
  export let telNum = "";
  export let placeholder = "";

  $: num = "";

  let code = data.dialingCode;

  let countriesSorted = countries;

  countriesSorted.sort((a, b) => a.Dial.localeCompare(b.Dial));

  function action(key) {
    value = code + num;
    telNum = num;
    if (key === "Enter") {
      dispatch("submit", { code: code, num: num });
    } else {
      dispatch("message", { code: code, num: num });
    }
  }
</script>

<div class={$$props.class} style="display: flex">  
  {#if showButton === true}
  <div class="chat-with-telfield" style="flex: 1 1 30%;">
    <select bind:value={code}>
      {#each countries as c}
        <option value={c.Dial}>{c.Unicode} +{c.Dial}</option>
      {/each}
    </select>
  </div>
  <div class="chat-with-telfield" style="flex: 2 1 50%;">
    <input
      type="tel"
      style="border: 1px solid #ccc"
      placeholder={placeholder?placeholder:(data.transYourPhone
        ? data.transYourPhone
        : "Your phone number")}
      bind:value={num}
      on:keyup={(e) => action(e.key)}
    />   
  </div>
  <div class="chat-with-telfield" style="flex: 3 1 20%;">
    <button on:click={()=>action("Enter")} class="whatapp-button half">
      <svg style="width: 18px;" aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
    </button>
  </div>
  {:else}
  <div class="chat-with-telfield" style="flex: 1">
    <select bind:value={code}>
      {#each countries as c}
        <option value={c.Dial}>{c.Unicode} +{c.Dial}</option>
      {/each}
    </select>
  </div>
  <div class="chat-with-telfield" style="flex:2">
    <input
      type="tel"
      style="border: 1px solid #ccc"
      placeholder={placeholder?placeholder:(data.transYourPhone
        ? data.transYourPhone
        : "Your phone number")}
      bind:value={num}
      on:keyup={(e) => action(e.key)}
    />
  </div>
  {/if}
</div>

<style>
  .chat-with-telfield input,
  .chat-with-telfield select {
    height: 35px;
    margin-bottom: 0;
    font-weight: 0;
    font-weight: normal;
    font-family: Arial;
    font-size: 14px;
    display: block;
    border-radius: 5px;
    display: block;
    width: 100%;
    max-width: 280px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }
  .whatapp-button{
    background: rgb(39, 217, 116);
    color: white;
    border: none;
    height: 35px;
    display: inline-block;
    border-radius: 5px;
    width: 100%;
  }
</style>
