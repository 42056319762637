export const countries = [{
    "Iso2": "BD",
    "Name": "Bangladesh",
    "Iso3": "BGD",
    "Unicode": "🇧🇩",
    "Dial": "880",
    "Currency": "BDT",
    "Capital": "Dhaka",
    "Continent": "AS"
}, {
    "Iso2": "BE",
    "Name": "Belgium",
    "Iso3": "BEL",
    "Unicode": "🇧🇪",
    "Dial": "32",
    "Currency": "EUR",
    "Capital": "Brussels",
    "Continent": "EU"
}, {
    "Iso2": "BF",
    "Name": "Burkina Faso",
    "Iso3": "BFA",
    "Unicode": "🇧🇫",
    "Dial": "226",
    "Currency": "XOF",
    "Capital": "Ouagadougou",
    "Continent": "AF"
}, {
    "Iso2": "BG",
    "Name": "Bulgaria",
    "Iso3": "BGR",
    "Unicode": "🇧🇬",
    "Dial": "359",
    "Currency": "BGN",
    "Capital": "Sofia",
    "Continent": "EU"
}, {
    "Iso2": "BA",
    "Name": "Bosnia and Herzegovina",
    "Iso3": "BIH",
    "Unicode": "🇧🇦",
    "Dial": "387",
    "Currency": "BAM",
    "Capital": "Sarajevo",
    "Continent": "EU"
}, {
    "Iso2": "BB",
    "Name": "Barbados",
    "Iso3": "BRB",
    "Unicode": "🇧🇧",
    "Dial": "1246",
    "Currency": "BBD",
    "Capital": "Bridgetown",
    "Continent": "NA"
}, {
    "Iso2": "WF",
    "Name": "Wallis and Futuna",
    "Iso3": "WLF",
    "Unicode": "🇼🇫",
    "Dial": "681",
    "Currency": "XPF",
    "Capital": "Mata Utu",
    "Continent": "OC"
}, {
    "Iso2": "BL",
    "Name": "Saint Barthelemy",
    "Iso3": "BLM",
    "Unicode": "🇧🇱",
    "Dial": "590",
    "Currency": "EUR",
    "Capital": "Gustavia",
    "Continent": "NA"
}, {
    "Iso2": "BM",
    "Name": "Bermuda",
    "Iso3": "BMU",
    "Unicode": "🇧🇲",
    "Dial": "1441",
    "Currency": "BMD",
    "Capital": "Hamilton",
    "Continent": "NA"
}, {
    "Iso2": "BN",
    "Name": "Brunei",
    "Iso3": "BRN",
    "Unicode": "🇧🇳",
    "Dial": "673",
    "Currency": "BND",
    "Capital": "Bandar Seri Begawan",
    "Continent": "AS"
}, {
    "Iso2": "BO",
    "Name": "Bolivia",
    "Iso3": "BOL",
    "Unicode": "🇧🇴",
    "Dial": "591",
    "Currency": "BOB",
    "Capital": "Sucre",
    "Continent": "SA"
}, {
    "Iso2": "BH",
    "Name": "Bahrain",
    "Iso3": "BHR",
    "Unicode": "🇧🇭",
    "Dial": "973",
    "Currency": "BHD",
    "Capital": "Manama",
    "Continent": "AS"
}, {
    "Iso2": "BI",
    "Name": "Burundi",
    "Iso3": "BDI",
    "Unicode": "🇧🇮",
    "Dial": "257",
    "Currency": "BIF",
    "Capital": "Bujumbura",
    "Continent": "AF"
}, {
    "Iso2": "BJ",
    "Name": "Benin",
    "Iso3": "BEN",
    "Unicode": "🇧🇯",
    "Dial": "229",
    "Currency": "XOF",
    "Capital": "Porto-Novo",
    "Continent": "AF"
}, {
    "Iso2": "BT",
    "Name": "Bhutan",
    "Iso3": "BTN",
    "Unicode": "🇧🇹",
    "Dial": "975",
    "Currency": "BTN",
    "Capital": "Thimphu",
    "Continent": "AS"
}, {
    "Iso2": "JM",
    "Name": "Jamaica",
    "Iso3": "JAM",
    "Unicode": "🇯🇲",
    "Dial": "1876",
    "Currency": "JMD",
    "Capital": "Kingston",
    "Continent": "NA"
}, {
    "Iso2": "BW",
    "Name": "Botswana",
    "Iso3": "BWA",
    "Unicode": "🇧🇼",
    "Dial": "267",
    "Currency": "BWP",
    "Capital": "Gaborone",
    "Continent": "AF"
}, {
    "Iso2": "WS",
    "Name": "Samoa",
    "Iso3": "WSM",
    "Unicode": "🇼🇸",
    "Dial": "685",
    "Currency": "WST",
    "Capital": "Apia",
    "Continent": "OC"
}, {
    "Iso2": "BR",
    "Name": "Brazil",
    "Iso3": "BRA",
    "Unicode": "🇧🇷",
    "Dial": "55",
    "Currency": "BRL",
    "Capital": "Brasilia",
    "Continent": "SA"
}, {
    "Iso2": "BS",
    "Name": "Bahamas",
    "Iso3": "BHS",
    "Unicode": "🇧🇸",
    "Dial": "1242",
    "Currency": "BSD",
    "Capital": "Nassau",
    "Continent": "NA"
}, {
    "Iso2": "JE",
    "Name": "Jersey",
    "Iso3": "JEY",
    "Unicode": "🇯🇪",
    "Dial": "441534",
    "Currency": "GBP",
    "Capital": "Saint Helier",
    "Continent": "EU"
}, {
    "Iso2": "BY",
    "Name": "Belarus",
    "Iso3": "BLR",
    "Unicode": "🇧🇾",
    "Dial": "375",
    "Currency": "BYR",
    "Capital": "Minsk",
    "Continent": "EU"
}, {
    "Iso2": "BZ",
    "Name": "Belize",
    "Iso3": "BLZ",
    "Unicode": "🇧🇿",
    "Dial": "501",
    "Currency": "BZD",
    "Capital": "Belmopan",
    "Continent": "NA"
}, {
    "Iso2": "RU",
    "Name": "Russia",
    "Iso3": "RUS",
    "Unicode": "🇷🇺",
    "Dial": "7",
    "Currency": "RUB",
    "Capital": "Moscow",
    "Continent": "EU"
}, {
    "Iso2": "RW",
    "Name": "Rwanda",
    "Iso3": "RWA",
    "Unicode": "🇷🇼",
    "Dial": "250",
    "Currency": "RWF",
    "Capital": "Kigali",
    "Continent": "AF"
}, {
    "Iso2": "RS",
    "Name": "Serbia",
    "Iso3": "SRB",
    "Unicode": "🇷🇸",
    "Dial": "381",
    "Currency": "RSD",
    "Capital": "Belgrade",
    "Continent": "EU"
}, {
    "Iso2": "TL",
    "Name": "East Timor",
    "Iso3": "TLS",
    "Unicode": "🇹🇱",
    "Dial": "670",
    "Currency": "USD",
    "Capital": "Dili",
    "Continent": "OC"
}, {
    "Iso2": "RE",
    "Name": "Reunion",
    "Iso3": "REU",
    "Unicode": "🇷🇪",
    "Dial": "262",
    "Currency": "EUR",
    "Capital": "Saint-Denis",
    "Continent": "AF"
}, {
    "Iso2": "TM",
    "Name": "Turkmenistan",
    "Iso3": "TKM",
    "Unicode": "🇹🇲",
    "Dial": "993",
    "Currency": "TMT",
    "Capital": "Ashgabat",
    "Continent": "AS"
}, {
    "Iso2": "TJ",
    "Name": "Tajikistan",
    "Iso3": "TJK",
    "Unicode": "🇹🇯",
    "Dial": "992",
    "Currency": "TJS",
    "Capital": "Dushanbe",
    "Continent": "AS"
}, {
    "Iso2": "RO",
    "Name": "Romania",
    "Iso3": "ROU",
    "Unicode": "🇷🇴",
    "Dial": "40",
    "Currency": "RON",
    "Capital": "Bucharest",
    "Continent": "EU"
}, {
    "Iso2": "GW",
    "Name": "Guinea-Bissau",
    "Iso3": "GNB",
    "Unicode": "🇬🇼",
    "Dial": "245",
    "Currency": "XOF",
    "Capital": "Bissau",
    "Continent": "AF"
}, {
    "Iso2": "GU",
    "Name": "Guam",
    "Iso3": "GUM",
    "Unicode": "🇬🇺",
    "Dial": "1671",
    "Currency": "USD",
    "Capital": "Hagatna",
    "Continent": "OC"
}, {
    "Iso2": "GT",
    "Name": "Guatemala",
    "Iso3": "GTM",
    "Unicode": "🇬🇹",
    "Dial": "502",
    "Currency": "GTQ",
    "Capital": "Guatemala City",
    "Continent": "NA"
}, {
    "Iso2": "GR",
    "Name": "Greece",
    "Iso3": "GRC",
    "Unicode": "🇬🇷",
    "Dial": "30",
    "Currency": "EUR",
    "Capital": "Athens",
    "Continent": "EU"
}, {
    "Iso2": "GQ",
    "Name": "Equatorial Guinea",
    "Iso3": "GNQ",
    "Unicode": "🇬🇶",
    "Dial": "240",
    "Currency": "XAF",
    "Capital": "Malabo",
    "Continent": "AF"
}, {
    "Iso2": "GP",
    "Name": "Guadeloupe",
    "Iso3": "GLP",
    "Unicode": "🇬🇵",
    "Dial": "590",
    "Currency": "EUR",
    "Capital": "Basse-Terre",
    "Continent": "NA"
}, {
    "Iso2": "JP",
    "Name": "Japan",
    "Iso3": "JPN",
    "Unicode": "🇯🇵",
    "Dial": "81",
    "Currency": "JPY",
    "Capital": "Tokyo",
    "Continent": "AS"
}, {
    "Iso2": "GY",
    "Name": "Guyana",
    "Iso3": "GUY",
    "Unicode": "🇬🇾",
    "Dial": "592",
    "Currency": "GYD",
    "Capital": "Georgetown",
    "Continent": "SA"
}, {
    "Iso2": "GG",
    "Name": "Guernsey",
    "Iso3": "GGY",
    "Unicode": "🇬🇬",
    "Dial": "441481",
    "Currency": "GBP",
    "Capital": "St Peter Port",
    "Continent": "EU"
}, {
    "Iso2": "GF",
    "Name": "French Guiana",
    "Iso3": "GUF",
    "Unicode": "🇬🇫",
    "Dial": "594",
    "Currency": "EUR",
    "Capital": "Cayenne",
    "Continent": "SA"
}, {
    "Iso2": "GE",
    "Name": "Georgia",
    "Iso3": "GEO",
    "Unicode": "🇬🇪",
    "Dial": "995",
    "Currency": "GEL",
    "Capital": "Tbilisi",
    "Continent": "AS"
}, {
    "Iso2": "GD",
    "Name": "Grenada",
    "Iso3": "GRD",
    "Unicode": "🇬🇩",
    "Dial": "1-473",
    "Currency": "XCD",
    "Capital": "St. George's",
    "Continent": "NA"
}, {
    "Iso2": "GB",
    "Name": "United Kingdom",
    "Iso3": "GBR",
    "Unicode": "🇬🇧",
    "Dial": "44",
    "Currency": "GBP",
    "Capital": "London",
    "Continent": "EU"
}, {
    "Iso2": "GA",
    "Name": "Gabon",
    "Iso3": "GAB",
    "Unicode": "🇬🇦",
    "Dial": "241",
    "Currency": "XAF",
    "Capital": "Libreville",
    "Continent": "AF"
}, {
    "Iso2": "SV",
    "Name": "El Salvador",
    "Iso3": "SLV",
    "Unicode": "🇸🇻",
    "Dial": "503",
    "Currency": "USD",
    "Capital": "San Salvador",
    "Continent": "NA"
}, {
    "Iso2": "GN",
    "Name": "Guinea",
    "Iso3": "GIN",
    "Unicode": "🇬🇳",
    "Dial": "224",
    "Currency": "GNF",
    "Capital": "Conakry",
    "Continent": "AF"
}, {
    "Iso2": "GM",
    "Name": "Gambia",
    "Iso3": "GMB",
    "Unicode": "🇬🇲",
    "Dial": "220",
    "Currency": "GMD",
    "Capital": "Banjul",
    "Continent": "AF"
}, {
    "Iso2": "GL",
    "Name": "Greenland",
    "Iso3": "GRL",
    "Unicode": "🇬🇱",
    "Dial": "299",
    "Currency": "DKK",
    "Capital": "Nuuk",
    "Continent": "NA"
}, {
    "Iso2": "GI",
    "Name": "Gibraltar",
    "Iso3": "GIB",
    "Unicode": "🇬🇮",
    "Dial": "350",
    "Currency": "GIP",
    "Capital": "Gibraltar",
    "Continent": "EU"
}, {
    "Iso2": "GH",
    "Name": "Ghana",
    "Iso3": "GHA",
    "Unicode": "🇬🇭",
    "Dial": "233",
    "Currency": "GHS",
    "Capital": "Accra",
    "Continent": "AF"
}, {
    "Iso2": "OM",
    "Name": "Oman",
    "Iso3": "OMN",
    "Unicode": "🇴🇲",
    "Dial": "968",
    "Currency": "OMR",
    "Capital": "Muscat",
    "Continent": "AS"
}, {
    "Iso2": "TN",
    "Name": "Tunisia",
    "Iso3": "TUN",
    "Unicode": "🇹🇳",
    "Dial": "216",
    "Currency": "TND",
    "Capital": "Tunis",
    "Continent": "AF"
}, {
    "Iso2": "JO",
    "Name": "Jordan",
    "Iso3": "JOR",
    "Unicode": "🇯🇴",
    "Dial": "962",
    "Currency": "JOD",
    "Capital": "Amman",
    "Continent": "AS"
}, {
    "Iso2": "HR",
    "Name": "Croatia",
    "Iso3": "HRV",
    "Unicode": "🇭🇷",
    "Dial": "385",
    "Currency": "HRK",
    "Capital": "Zagreb",
    "Continent": "EU"
}, {
    "Iso2": "HT",
    "Name": "Haiti",
    "Iso3": "HTI",
    "Unicode": "🇭🇹",
    "Dial": "509",
    "Currency": "HTG",
    "Capital": "Port-au-Prince",
    "Continent": "NA"
}, {
    "Iso2": "HU",
    "Name": "Hungary",
    "Iso3": "HUN",
    "Unicode": "🇭🇺",
    "Dial": "36",
    "Currency": "HUF",
    "Capital": "Budapest",
    "Continent": "EU"
}, {
    "Iso2": "HK",
    "Name": "Hong Kong",
    "Iso3": "HKG",
    "Unicode": "🇭🇰",
    "Dial": "852",
    "Currency": "HKD",
    "Capital": "Hong Kong",
    "Continent": "AS"
}, {
    "Iso2": "HN",
    "Name": "Honduras",
    "Iso3": "HND",
    "Unicode": "🇭🇳",
    "Dial": "504",
    "Currency": "HNL",
    "Capital": "Tegucigalpa",
    "Continent": "NA"
}, {
    "Iso2": "VE",
    "Name": "Venezuela",
    "Iso3": "VEN",
    "Unicode": "🇻🇪",
    "Dial": "58",
    "Currency": "VEF",
    "Capital": "Caracas",
    "Continent": "SA"
}, {
    "Iso2": "PR",
    "Name": "Puerto Rico",
    "Iso3": "PRI",
    "Unicode": "🇵🇷",
    "Dial": "1-787",
    "Currency": "USD",
    "Capital": "San Juan",
    "Continent": "NA"
}, {
    "Iso2": "PS",
    "Name": "Palestinian Territory",
    "Iso3": "PSE",
    "Unicode": "🇵🇸",
    "Dial": "970",
    "Currency": "ILS",
    "Capital": "East Jerusalem",
    "Continent": "AS"
}, {
    "Iso2": "PW",
    "Name": "Palau",
    "Iso3": "PLW",
    "Unicode": "🇵🇼",
    "Dial": "680",
    "Currency": "USD",
    "Capital": "Melekeok",
    "Continent": "OC"
}, {
    "Iso2": "PT",
    "Name": "Portugal",
    "Iso3": "PRT",
    "Unicode": "🇵🇹",
    "Dial": "351",
    "Currency": "EUR",
    "Capital": "Lisbon",
    "Continent": "EU"
}, {
    "Iso2": "SJ",
    "Name": "Svalbard and Jan Mayen",
    "Iso3": "SJM",
    "Unicode": "🇸🇯",
    "Dial": "47",
    "Currency": "NOK",
    "Capital": "Longyearbyen",
    "Continent": "EU"
}, {
    "Iso2": "PY",
    "Name": "Paraguay",
    "Iso3": "PRY",
    "Unicode": "🇵🇾",
    "Dial": "595",
    "Currency": "PYG",
    "Capital": "Asuncion",
    "Continent": "SA"
}, {
    "Iso2": "IQ",
    "Name": "Iraq",
    "Iso3": "IRQ",
    "Unicode": "🇮🇶",
    "Dial": "964",
    "Currency": "IQD",
    "Capital": "Baghdad",
    "Continent": "AS"
}, {
    "Iso2": "PA",
    "Name": "Panama",
    "Iso3": "PAN",
    "Unicode": "🇵🇦",
    "Dial": "507",
    "Currency": "PAB",
    "Capital": "Panama City",
    "Continent": "NA"
}, {
    "Iso2": "PF",
    "Name": "French Polynesia",
    "Iso3": "PYF",
    "Unicode": "🇵🇫",
    "Dial": "689",
    "Currency": "XPF",
    "Capital": "Papeete",
    "Continent": "OC"
}, {
    "Iso2": "PG",
    "Name": "Papua New Guinea",
    "Iso3": "PNG",
    "Unicode": "🇵🇬",
    "Dial": "675",
    "Currency": "PGK",
    "Capital": "Port Moresby",
    "Continent": "OC"
}, {
    "Iso2": "PE",
    "Name": "Peru",
    "Iso3": "PER",
    "Unicode": "🇵🇪",
    "Dial": "51",
    "Currency": "PEN",
    "Capital": "Lima",
    "Continent": "SA"
}, {
    "Iso2": "PK",
    "Name": "Pakistan",
    "Iso3": "PAK",
    "Unicode": "🇵🇰",
    "Dial": "92",
    "Currency": "PKR",
    "Capital": "Islamabad",
    "Continent": "AS"
}, {
    "Iso2": "PH",
    "Name": "Philippines",
    "Iso3": "PHL",
    "Unicode": "🇵🇭",
    "Dial": "63",
    "Currency": "PHP",
    "Capital": "Manila",
    "Continent": "AS"
}, {
    "Iso2": "PN",
    "Name": "Pitcairn",
    "Iso3": "PCN",
    "Unicode": "🇵🇳",
    "Dial": "870",
    "Currency": "NZD",
    "Capital": "Adamstown",
    "Continent": "OC"
}, {
    "Iso2": "PL",
    "Name": "Poland",
    "Iso3": "POL",
    "Unicode": "🇵🇱",
    "Dial": "48",
    "Currency": "PLN",
    "Capital": "Warsaw",
    "Continent": "EU"
}, {
    "Iso2": "PM",
    "Name": "Saint Pierre and Miquelon",
    "Iso3": "SPM",
    "Unicode": "🇵🇲",
    "Dial": "508",
    "Currency": "EUR",
    "Capital": "Saint-Pierre",
    "Continent": "NA"
}, {
    "Iso2": "ZM",
    "Name": "Zambia",
    "Iso3": "ZMB",
    "Unicode": "🇿🇲",
    "Dial": "260",
    "Currency": "ZMK",
    "Capital": "Lusaka",
    "Continent": "AF"
}, {
    "Iso2": "EH",
    "Name": "Western Sahara",
    "Iso3": "ESH",
    "Unicode": "🇪🇭",
    "Dial": "212",
    "Currency": "MAD",
    "Capital": "El-Aaiun",
    "Continent": "AF"
}, {
    "Iso2": "EE",
    "Name": "Estonia",
    "Iso3": "EST",
    "Unicode": "🇪🇪",
    "Dial": "372",
    "Currency": "EUR",
    "Capital": "Tallinn",
    "Continent": "EU"
}, {
    "Iso2": "EG",
    "Name": "Egypt",
    "Iso3": "EGY",
    "Unicode": "🇪🇬",
    "Dial": "20",
    "Currency": "EGP",
    "Capital": "Cairo",
    "Continent": "AF"
}, {
    "Iso2": "ZA",
    "Name": "South Africa",
    "Iso3": "ZAF",
    "Unicode": "🇿🇦",
    "Dial": "27",
    "Currency": "ZAR",
    "Capital": "Pretoria",
    "Continent": "AF"
}, {
    "Iso2": "EC",
    "Name": "Ecuador",
    "Iso3": "ECU",
    "Unicode": "🇪🇨",
    "Dial": "593",
    "Currency": "USD",
    "Capital": "Quito",
    "Continent": "SA"
}, {
    "Iso2": "IT",
    "Name": "Italy",
    "Iso3": "ITA",
    "Unicode": "🇮🇹",
    "Dial": "39",
    "Currency": "EUR",
    "Capital": "Rome",
    "Continent": "EU"
}, {
    "Iso2": "VN",
    "Name": "Vietnam",
    "Iso3": "VNM",
    "Unicode": "🇻🇳",
    "Dial": "84",
    "Currency": "VND",
    "Capital": "Hanoi",
    "Continent": "AS"
}, {
    "Iso2": "SB",
    "Name": "Solomon Islands",
    "Iso3": "SLB",
    "Unicode": "🇸🇧",
    "Dial": "677",
    "Currency": "SBD",
    "Capital": "Honiara",
    "Continent": "OC"
}, {
    "Iso2": "ET",
    "Name": "Ethiopia",
    "Iso3": "ETH",
    "Unicode": "🇪🇹",
    "Dial": "251",
    "Currency": "ETB",
    "Capital": "Addis Ababa",
    "Continent": "AF"
}, {
    "Iso2": "SO",
    "Name": "Somalia",
    "Iso3": "SOM",
    "Unicode": "🇸🇴",
    "Dial": "252",
    "Currency": "SOS",
    "Capital": "Mogadishu",
    "Continent": "AF"
}, {
    "Iso2": "ZW",
    "Name": "Zimbabwe",
    "Iso3": "ZWE",
    "Unicode": "🇿🇼",
    "Dial": "263",
    "Currency": "ZWL",
    "Capital": "Harare",
    "Continent": "AF"
}, {
    "Iso2": "SA",
    "Name": "Saudi Arabia",
    "Iso3": "SAU",
    "Unicode": "🇸🇦",
    "Dial": "966",
    "Currency": "SAR",
    "Capital": "Riyadh",
    "Continent": "AS"
}, {
    "Iso2": "ES",
    "Name": "Spain",
    "Iso3": "ESP",
    "Unicode": "🇪🇸",
    "Dial": "34",
    "Currency": "EUR",
    "Capital": "Madrid",
    "Continent": "EU"
}, {
    "Iso2": "ER",
    "Name": "Eritrea",
    "Iso3": "ERI",
    "Unicode": "🇪🇷",
    "Dial": "291",
    "Currency": "ERN",
    "Capital": "Asmara",
    "Continent": "AF"
}, {
    "Iso2": "ME",
    "Name": "Montenegro",
    "Iso3": "MNE",
    "Unicode": "🇲🇪",
    "Dial": "382",
    "Currency": "EUR",
    "Capital": "Podgorica",
    "Continent": "EU"
}, {
    "Iso2": "MD",
    "Name": "Moldova",
    "Iso3": "MDA",
    "Unicode": "🇲🇩",
    "Dial": "373",
    "Currency": "MDL",
    "Capital": "Chisinau",
    "Continent": "EU"
}, {
    "Iso2": "MG",
    "Name": "Madagascar",
    "Iso3": "MDG",
    "Unicode": "🇲🇬",
    "Dial": "261",
    "Currency": "MGA",
    "Capital": "Antananarivo",
    "Continent": "AF"
}, {
    "Iso2": "MF",
    "Name": "Saint Martin",
    "Iso3": "MAF",
    "Unicode": "🇲🇫",
    "Dial": "590",
    "Currency": "EUR",
    "Capital": "Marigot",
    "Continent": "NA"
}, {
    "Iso2": "MA",
    "Name": "Morocco",
    "Iso3": "MAR",
    "Unicode": "🇲🇦",
    "Dial": "212",
    "Currency": "MAD",
    "Capital": "Rabat",
    "Continent": "AF"
}, {
    "Iso2": "MC",
    "Name": "Monaco",
    "Iso3": "MCO",
    "Unicode": "🇲🇨",
    "Dial": "377",
    "Currency": "EUR",
    "Capital": "Monaco",
    "Continent": "EU"
}, {
    "Iso2": "UZ",
    "Name": "Uzbekistan",
    "Iso3": "UZB",
    "Unicode": "🇺🇿",
    "Dial": "998",
    "Currency": "UZS",
    "Capital": "Tashkent",
    "Continent": "AS"
}, {
    "Iso2": "MM",
    "Name": "Myanmar",
    "Iso3": "MMR",
    "Unicode": "🇲🇲",
    "Dial": "95",
    "Currency": "MMK",
    "Capital": "Nay Pyi Taw",
    "Continent": "AS"
}, {
    "Iso2": "ML",
    "Name": "Mali",
    "Iso3": "MLI",
    "Unicode": "🇲🇱",
    "Dial": "223",
    "Currency": "XOF",
    "Capital": "Bamako",
    "Continent": "AF"
}, {
    "Iso2": "MO",
    "Name": "Macao",
    "Iso3": "MAC",
    "Unicode": "🇲🇴",
    "Dial": "853",
    "Currency": "MOP",
    "Capital": "Macao",
    "Continent": "AS"
}, {
    "Iso2": "MN",
    "Name": "Mongolia",
    "Iso3": "MNG",
    "Unicode": "🇲🇳",
    "Dial": "976",
    "Currency": "MNT",
    "Capital": "Ulan Bator",
    "Continent": "AS"
}, {
    "Iso2": "MH",
    "Name": "Marshall Islands",
    "Iso3": "MHL",
    "Unicode": "🇲🇭",
    "Dial": "692",
    "Currency": "USD",
    "Capital": "Majuro",
    "Continent": "OC"
}, {
    "Iso2": "MK",
    "Name": "Macedonia",
    "Iso3": "MKD",
    "Unicode": "🇲🇰",
    "Dial": "389",
    "Currency": "MKD",
    "Capital": "Skopje",
    "Continent": "EU"
}, {
    "Iso2": "MU",
    "Name": "Mauritius",
    "Iso3": "MUS",
    "Unicode": "🇲🇺",
    "Dial": "230",
    "Currency": "MUR",
    "Capital": "Port Louis",
    "Continent": "AF"
}, {
    "Iso2": "MT",
    "Name": "Malta",
    "Iso3": "MLT",
    "Unicode": "🇲🇹",
    "Dial": "356",
    "Currency": "EUR",
    "Capital": "Valletta",
    "Continent": "EU"
}, {
    "Iso2": "MW",
    "Name": "Malawi",
    "Iso3": "MWI",
    "Unicode": "🇲🇼",
    "Dial": "265",
    "Currency": "MWK",
    "Capital": "Lilongwe",
    "Continent": "AF"
}, {
    "Iso2": "MV",
    "Name": "Maldives",
    "Iso3": "MDV",
    "Unicode": "🇲🇻",
    "Dial": "960",
    "Currency": "MVR",
    "Capital": "Male",
    "Continent": "AS"
}, {
    "Iso2": "MQ",
    "Name": "Martinique",
    "Iso3": "MTQ",
    "Unicode": "🇲🇶",
    "Dial": "596",
    "Currency": "EUR",
    "Capital": "Fort-de-France",
    "Continent": "NA"
}, {
    "Iso2": "MP",
    "Name": "Northern Mariana Islands",
    "Iso3": "MNP",
    "Unicode": "🇲🇵",
    "Dial": "1670",
    "Currency": "USD",
    "Capital": "Saipan",
    "Continent": "OC"
}, {
    "Iso2": "MS",
    "Name": "Montserrat",
    "Iso3": "MSR",
    "Unicode": "🇲🇸",
    "Dial": "1664",
    "Currency": "XCD",
    "Capital": "Plymouth",
    "Continent": "NA"
}, {
    "Iso2": "MR",
    "Name": "Mauritania",
    "Iso3": "MRT",
    "Unicode": "🇲🇷",
    "Dial": "222",
    "Currency": "MRO",
    "Capital": "Nouakchott",
    "Continent": "AF"
}, {
    "Iso2": "IM",
    "Name": "Isle of Man",
    "Iso3": "IMN",
    "Unicode": "🇮🇲",
    "Dial": "441624",
    "Currency": "GBP",
    "Capital": "Douglas, Isle of Man",
    "Continent": "EU"
}, {
    "Iso2": "UG",
    "Name": "Uganda",
    "Iso3": "UGA",
    "Unicode": "🇺🇬",
    "Dial": "256",
    "Currency": "UGX",
    "Capital": "Kampala",
    "Continent": "AF"
}, {
    "Iso2": "TZ",
    "Name": "Tanzania",
    "Iso3": "TZA",
    "Unicode": "🇹🇿",
    "Dial": "255",
    "Currency": "TZS",
    "Capital": "Dodoma",
    "Continent": "AF"
}, {
    "Iso2": "MY",
    "Name": "Malaysia",
    "Iso3": "MYS",
    "Unicode": "🇲🇾",
    "Dial": "60",
    "Currency": "MYR",
    "Capital": "Kuala Lumpur",
    "Continent": "AS"
}, {
    "Iso2": "MX",
    "Name": "Mexico",
    "Iso3": "MEX",
    "Unicode": "🇲🇽",
    "Dial": "52",
    "Currency": "MXN",
    "Capital": "Mexico City",
    "Continent": "NA"
}, {
    "Iso2": "IL",
    "Name": "Israel",
    "Iso3": "ISR",
    "Unicode": "🇮🇱",
    "Dial": "972",
    "Currency": "ILS",
    "Capital": "Jerusalem",
    "Continent": "AS"
}, {
    "Iso2": "FR",
    "Name": "France",
    "Iso3": "FRA",
    "Unicode": "🇫🇷",
    "Dial": "33",
    "Currency": "EUR",
    "Capital": "Paris",
    "Continent": "EU"
}, {
    "Iso2": "IO",
    "Name": "British Indian Ocean Territory",
    "Iso3": "IOT",
    "Unicode": "🇮🇴",
    "Dial": "246",
    "Currency": "USD",
    "Capital": "Diego Garcia",
    "Continent": "AS"
}, {
    "Iso2": "SH",
    "Name": "Saint Helena",
    "Iso3": "SHN",
    "Unicode": "🇸🇭",
    "Dial": "290",
    "Currency": "SHP",
    "Capital": "Jamestown",
    "Continent": "AF"
}, {
    "Iso2": "FI",
    "Name": "Finland",
    "Iso3": "FIN",
    "Unicode": "🇫🇮",
    "Dial": "358",
    "Currency": "EUR",
    "Capital": "Helsinki",
    "Continent": "EU"
}, {
    "Iso2": "FJ",
    "Name": "Fiji",
    "Iso3": "FJI",
    "Unicode": "🇫🇯",
    "Dial": "679",
    "Currency": "FJD",
    "Capital": "Suva",
    "Continent": "OC"
}, {
    "Iso2": "FK",
    "Name": "Falkland Islands",
    "Iso3": "FLK",
    "Unicode": "🇫🇰",
    "Dial": "500",
    "Currency": "FKP",
    "Capital": "Stanley",
    "Continent": "SA"
}, {
    "Iso2": "FM",
    "Name": "Micronesia",
    "Iso3": "FSM",
    "Unicode": "🇫🇲",
    "Dial": "691",
    "Currency": "USD",
    "Capital": "Palikir",
    "Continent": "OC"
}, {
    "Iso2": "FO",
    "Name": "Faroe Islands",
    "Iso3": "FRO",
    "Unicode": "🇫🇴",
    "Dial": "298",
    "Currency": "DKK",
    "Capital": "Torshavn",
    "Continent": "EU"
}, {
    "Iso2": "NI",
    "Name": "Nicaragua",
    "Iso3": "NIC",
    "Unicode": "🇳🇮",
    "Dial": "505",
    "Currency": "NIO",
    "Capital": "Managua",
    "Continent": "NA"
}, {
    "Iso2": "NL",
    "Name": "Netherlands",
    "Iso3": "NLD",
    "Unicode": "🇳🇱",
    "Dial": "31",
    "Currency": "EUR",
    "Capital": "Amsterdam",
    "Continent": "EU"
}, {
    "Iso2": "NO",
    "Name": "Norway",
    "Iso3": "NOR",
    "Unicode": "🇳🇴",
    "Dial": "47",
    "Currency": "NOK",
    "Capital": "Oslo",
    "Continent": "EU"
}, {
    "Iso2": "NA",
    "Name": "Namibia",
    "Iso3": "NAM",
    "Unicode": "🇳🇦",
    "Dial": "264",
    "Currency": "NAD",
    "Capital": "Windhoek",
    "Continent": "AF"
}, {
    "Iso2": "VU",
    "Name": "Vanuatu",
    "Iso3": "VUT",
    "Unicode": "🇻🇺",
    "Dial": "678",
    "Currency": "VUV",
    "Capital": "Port Vila",
    "Continent": "OC"
}, {
    "Iso2": "NC",
    "Name": "New Caledonia",
    "Iso3": "NCL",
    "Unicode": "🇳🇨",
    "Dial": "687",
    "Currency": "XPF",
    "Capital": "Noumea",
    "Continent": "OC"
}, {
    "Iso2": "NE",
    "Name": "Niger",
    "Iso3": "NER",
    "Unicode": "🇳🇪",
    "Dial": "227",
    "Currency": "XOF",
    "Capital": "Niamey",
    "Continent": "AF"
}, {
    "Iso2": "NF",
    "Name": "Norfolk Island",
    "Iso3": "NFK",
    "Unicode": "🇳🇫",
    "Dial": "672",
    "Currency": "AUD",
    "Capital": "Kingston",
    "Continent": "OC"
}, {
    "Iso2": "NG",
    "Name": "Nigeria",
    "Iso3": "NGA",
    "Unicode": "🇳🇬",
    "Dial": "234",
    "Currency": "NGN",
    "Capital": "Abuja",
    "Continent": "AF"
}, {
    "Iso2": "NZ",
    "Name": "New Zealand",
    "Iso3": "NZL",
    "Unicode": "🇳🇿",
    "Dial": "64",
    "Currency": "NZD",
    "Capital": "Wellington",
    "Continent": "OC"
}, {
    "Iso2": "NP",
    "Name": "Nepal",
    "Iso3": "NPL",
    "Unicode": "🇳🇵",
    "Dial": "977",
    "Currency": "NPR",
    "Capital": "Kathmandu",
    "Continent": "AS"
}, {
    "Iso2": "NR",
    "Name": "Nauru",
    "Iso3": "NRU",
    "Unicode": "🇳🇷",
    "Dial": "674",
    "Currency": "AUD",
    "Capital": "Yaren",
    "Continent": "OC"
}, {
    "Iso2": "NU",
    "Name": "Niue",
    "Iso3": "NIU",
    "Unicode": "🇳🇺",
    "Dial": "683",
    "Currency": "NZD",
    "Capital": "Alofi",
    "Continent": "OC"
}, {
    "Iso2": "CK",
    "Name": "Cook Islands",
    "Iso3": "COK",
    "Unicode": "🇨🇰",
    "Dial": "682",
    "Currency": "NZD",
    "Capital": "Avarua",
    "Continent": "OC"
}, {
    "Iso2": "CI",
    "Name": "Ivory Coast",
    "Iso3": "CIV",
    "Unicode": "🇨🇮",
    "Dial": "225",
    "Currency": "XOF",
    "Capital": "Yamoussoukro",
    "Continent": "AF"
}, {
    "Iso2": "CH",
    "Name": "Switzerland",
    "Iso3": "CHE",
    "Unicode": "🇨🇭",
    "Dial": "41",
    "Currency": "CHF",
    "Capital": "Berne",
    "Continent": "EU"
}, {
    "Iso2": "CO",
    "Name": "Colombia",
    "Iso3": "COL",
    "Unicode": "🇨🇴",
    "Dial": "57",
    "Currency": "COP",
    "Capital": "Bogota",
    "Continent": "SA"
}, {
    "Iso2": "CN",
    "Name": "China",
    "Iso3": "CHN",
    "Unicode": "🇨🇳",
    "Dial": "86",
    "Currency": "CNY",
    "Capital": "Beijing",
    "Continent": "AS"
}, {
    "Iso2": "CM",
    "Name": "Cameroon",
    "Iso3": "CMR",
    "Unicode": "🇨🇲",
    "Dial": "237",
    "Currency": "XAF",
    "Capital": "Yaounde",
    "Continent": "AF"
}, {
    "Iso2": "CL",
    "Name": "Chile",
    "Iso3": "CHL",
    "Unicode": "🇨🇱",
    "Dial": "56",
    "Currency": "CLP",
    "Capital": "Santiago",
    "Continent": "SA"
}, {
    "Iso2": "CC",
    "Name": "Cocos Islands",
    "Iso3": "CCK",
    "Unicode": "🇨🇨",
    "Dial": "61",
    "Currency": "AUD",
    "Capital": "West Island",
    "Continent": "AS"
}, {
    "Iso2": "CA",
    "Name": "Canada",
    "Iso3": "CAN",
    "Unicode": "🇨🇦",
    "Dial": "1",
    "Currency": "CAD",
    "Capital": "Ottawa",
    "Continent": "NA"
}, {
    "Iso2": "CG",
    "Name": "Republic of the Congo",
    "Iso3": "COG",
    "Unicode": "🇨🇬",
    "Dial": "242",
    "Currency": "XAF",
    "Capital": "Brazzaville",
    "Continent": "AF"
}, {
    "Iso2": "CF",
    "Name": "Central African Republic",
    "Iso3": "CAF",
    "Unicode": "🇨🇫",
    "Dial": "236",
    "Currency": "XAF",
    "Capital": "Bangui",
    "Continent": "AF"
}, {
    "Iso2": "CD",
    "Name": "Democratic Republic of the Congo",
    "Iso3": "COD",
    "Unicode": "🇨🇩",
    "Dial": "243",
    "Currency": "CDF",
    "Capital": "Kinshasa",
    "Continent": "AF"
}, {
    "Iso2": "CZ",
    "Name": "Czech Republic",
    "Iso3": "CZE",
    "Unicode": "🇨🇿",
    "Dial": "420",
    "Currency": "CZK",
    "Capital": "Prague",
    "Continent": "EU"
}, {
    "Iso2": "CY",
    "Name": "Cyprus",
    "Iso3": "CYP",
    "Unicode": "🇨🇾",
    "Dial": "357",
    "Currency": "EUR",
    "Capital": "Nicosia",
    "Continent": "EU"
}, {
    "Iso2": "CX",
    "Name": "Christmas Island",
    "Iso3": "CXR",
    "Unicode": "🇨🇽",
    "Dial": "61",
    "Currency": "AUD",
    "Capital": "Flying Fish Cove",
    "Continent": "AS"
}, {
    "Iso2": "CR",
    "Name": "Costa Rica",
    "Iso3": "CRI",
    "Unicode": "🇨🇷",
    "Dial": "506",
    "Currency": "CRC",
    "Capital": "San Jose",
    "Continent": "NA"
}, {
    "Iso2": "CW",
    "Name": "Curacao",
    "Iso3": "CUW",
    "Unicode": "🇨🇼",
    "Dial": "599",
    "Currency": "ANG",
    "Capital": " Willemstad",
    "Continent": "NA"
}, {
    "Iso2": "CV",
    "Name": "Cape Verde",
    "Iso3": "CPV",
    "Unicode": "🇨🇻",
    "Dial": "238",
    "Currency": "CVE",
    "Capital": "Praia",
    "Continent": "AF"
}, {
    "Iso2": "CU",
    "Name": "Cuba",
    "Iso3": "CUB",
    "Unicode": "🇨🇺",
    "Dial": "53",
    "Currency": "CUP",
    "Capital": "Havana",
    "Continent": "NA"
}, {
    "Iso2": "SZ",
    "Name": "Swaziland",
    "Iso3": "SWZ",
    "Unicode": "🇸🇿",
    "Dial": "268",
    "Currency": "SZL",
    "Capital": "Mbabane",
    "Continent": "AF"
}, {
    "Iso2": "SY",
    "Name": "Syria",
    "Iso3": "SYR",
    "Unicode": "🇸🇾",
    "Dial": "963",
    "Currency": "SYP",
    "Capital": "Damascus",
    "Continent": "AS"
}, {
    "Iso2": "SX",
    "Name": "Sint Maarten",
    "Iso3": "SXM",
    "Unicode": "🇸🇽",
    "Dial": "599",
    "Currency": "ANG",
    "Capital": "Philipsburg",
    "Continent": "NA"
}, {
    "Iso2": "KG",
    "Name": "Kyrgyzstan",
    "Iso3": "KGZ",
    "Unicode": "🇰🇬",
    "Dial": "996",
    "Currency": "KGS",
    "Capital": "Bishkek",
    "Continent": "AS"
}, {
    "Iso2": "KE",
    "Name": "Kenya",
    "Iso3": "KEN",
    "Unicode": "🇰🇪",
    "Dial": "254",
    "Currency": "KES",
    "Capital": "Nairobi",
    "Continent": "AF"
}, {
    "Iso2": "SS",
    "Name": "South Sudan",
    "Iso3": "SSD",
    "Unicode": "🇸🇸",
    "Dial": "211",
    "Currency": "SSP",
    "Capital": "Juba",
    "Continent": "AF"
}, {
    "Iso2": "SR",
    "Name": "Suriname",
    "Iso3": "SUR",
    "Unicode": "🇸🇷",
    "Dial": "597",
    "Currency": "SRD",
    "Capital": "Paramaribo",
    "Continent": "SA"
}, {
    "Iso2": "KI",
    "Name": "Kiribati",
    "Iso3": "KIR",
    "Unicode": "🇰🇮",
    "Dial": "686",
    "Currency": "AUD",
    "Capital": "Tarawa",
    "Continent": "OC"
}, {
    "Iso2": "KH",
    "Name": "Cambodia",
    "Iso3": "KHM",
    "Unicode": "🇰🇭",
    "Dial": "855",
    "Currency": "KHR",
    "Capital": "Phnom Penh",
    "Continent": "AS"
}, {
    "Iso2": "KN",
    "Name": "Saint Kitts and Nevis",
    "Iso3": "KNA",
    "Unicode": "🇰🇳",
    "Dial": "1869",
    "Currency": "XCD",
    "Capital": "Basseterre",
    "Continent": "NA"
}, {
    "Iso2": "KM",
    "Name": "Comoros",
    "Iso3": "COM",
    "Unicode": "🇰🇲",
    "Dial": "269",
    "Currency": "KMF",
    "Capital": "Moroni",
    "Continent": "AF"
}, {
    "Iso2": "ST",
    "Name": "Sao Tome and Principe",
    "Iso3": "STP",
    "Unicode": "🇸🇹",
    "Dial": "239",
    "Currency": "STD",
    "Capital": "Sao Tome",
    "Continent": "AF"
}, {
    "Iso2": "SK",
    "Name": "Slovakia",
    "Iso3": "SVK",
    "Unicode": "🇸🇰",
    "Dial": "421",
    "Currency": "EUR",
    "Capital": "Bratislava",
    "Continent": "EU"
}, {
    "Iso2": "KR",
    "Name": "South Korea",
    "Iso3": "KOR",
    "Unicode": "🇰🇷",
    "Dial": "82",
    "Currency": "KRW",
    "Capital": "Seoul",
    "Continent": "AS"
}, {
    "Iso2": "SI",
    "Name": "Slovenia",
    "Iso3": "SVN",
    "Unicode": "🇸🇮",
    "Dial": "386",
    "Currency": "EUR",
    "Capital": "Ljubljana",
    "Continent": "EU"
}, {
    "Iso2": "KP",
    "Name": "North Korea",
    "Iso3": "PRK",
    "Unicode": "🇰🇵",
    "Dial": "850",
    "Currency": "KPW",
    "Capital": "Pyongyang",
    "Continent": "AS"
}, {
    "Iso2": "KW",
    "Name": "Kuwait",
    "Iso3": "KWT",
    "Unicode": "🇰🇼",
    "Dial": "965",
    "Currency": "KWD",
    "Capital": "Kuwait City",
    "Continent": "AS"
}, {
    "Iso2": "SN",
    "Name": "Senegal",
    "Iso3": "SEN",
    "Unicode": "🇸🇳",
    "Dial": "221",
    "Currency": "XOF",
    "Capital": "Dakar",
    "Continent": "AF"
}, {
    "Iso2": "SM",
    "Name": "San Marino",
    "Iso3": "SMR",
    "Unicode": "🇸🇲",
    "Dial": "378",
    "Currency": "EUR",
    "Capital": "San Marino",
    "Continent": "EU"
}, {
    "Iso2": "SL",
    "Name": "Sierra Leone",
    "Iso3": "SLE",
    "Unicode": "🇸🇱",
    "Dial": "232",
    "Currency": "SLL",
    "Capital": "Freetown",
    "Continent": "AF"
}, {
    "Iso2": "SC",
    "Name": "Seychelles",
    "Iso3": "SYC",
    "Unicode": "🇸🇨",
    "Dial": "248",
    "Currency": "SCR",
    "Capital": "Victoria",
    "Continent": "AF"
}, {
    "Iso2": "KZ",
    "Name": "Kazakhstan",
    "Iso3": "KAZ",
    "Unicode": "🇰🇿",
    "Dial": "7",
    "Currency": "KZT",
    "Capital": "Astana",
    "Continent": "AS"
}, {
    "Iso2": "KY",
    "Name": "Cayman Islands",
    "Iso3": "CYM",
    "Unicode": "🇰🇾",
    "Dial": "1345",
    "Currency": "KYD",
    "Capital": "George Town",
    "Continent": "NA"
}, {
    "Iso2": "SG",
    "Name": "Singapore",
    "Iso3": "SGP",
    "Unicode": "🇸🇬",
    "Dial": "65",
    "Currency": "SGD",
    "Capital": "Singapur",
    "Continent": "AS"
}, {
    "Iso2": "SE",
    "Name": "Sweden",
    "Iso3": "SWE",
    "Unicode": "🇸🇪",
    "Dial": "46",
    "Currency": "SEK",
    "Capital": "Stockholm",
    "Continent": "EU"
}, {
    "Iso2": "SD",
    "Name": "Sudan",
    "Iso3": "SDN",
    "Unicode": "🇸🇩",
    "Dial": "249",
    "Currency": "SDG",
    "Capital": "Khartoum",
    "Continent": "AF"
}, {
    "Iso2": "DO",
    "Name": "Dominican Republic",
    "Iso3": "DOM",
    "Unicode": "🇩🇴",
    "Dial": "1809",
    "Currency": "DOP",
    "Capital": "Santo Domingo",
    "Continent": "NA"
}, {
    "Iso2": "DM",
    "Name": "Dominica",
    "Iso3": "DMA",
    "Unicode": "🇩🇲",
    "Dial": "1767",
    "Currency": "XCD",
    "Capital": "Roseau",
    "Continent": "NA"
}, {
    "Iso2": "DJ",
    "Name": "Djibouti",
    "Iso3": "DJI",
    "Unicode": "🇩🇯",
    "Dial": "253",
    "Currency": "DJF",
    "Capital": "Djibouti",
    "Continent": "AF"
}, {
    "Iso2": "DK",
    "Name": "Denmark",
    "Iso3": "DNK",
    "Unicode": "🇩🇰",
    "Dial": "45",
    "Currency": "DKK",
    "Capital": "Copenhagen",
    "Continent": "EU"
}, {
    "Iso2": "VG",
    "Name": "British Virgin Islands",
    "Iso3": "VGB",
    "Unicode": "🇻🇬",
    "Dial": "1284",
    "Currency": "USD",
    "Capital": "Road Town",
    "Continent": "NA"
}, {
    "Iso2": "DE",
    "Name": "Germany",
    "Iso3": "DEU",
    "Unicode": "🇩🇪",
    "Dial": "49",
    "Currency": "EUR",
    "Capital": "Berlin",
    "Continent": "EU"
}, {
    "Iso2": "YE",
    "Name": "Yemen",
    "Iso3": "YEM",
    "Unicode": "🇾🇪",
    "Dial": "967",
    "Currency": "YER",
    "Capital": "Sanaa",
    "Continent": "AS"
}, {
    "Iso2": "DZ",
    "Name": "Algeria",
    "Iso3": "DZA",
    "Unicode": "🇩🇿",
    "Dial": "213",
    "Currency": "DZD",
    "Capital": "Algiers",
    "Continent": "AF"
}, {
    "Iso2": "US",
    "Name": "United States",
    "Iso3": "USA",
    "Unicode": "🇺🇸",
    "Dial": "1",
    "Currency": "USD",
    "Capital": "Washington",
    "Continent": "NA"
}, {
    "Iso2": "UY",
    "Name": "Uruguay",
    "Iso3": "URY",
    "Unicode": "🇺🇾",
    "Dial": "598",
    "Currency": "UYU",
    "Capital": "Montevideo",
    "Continent": "SA"
}, {
    "Iso2": "YT",
    "Name": "Mayotte",
    "Iso3": "MYT",
    "Unicode": "🇾🇹",
    "Dial": "262",
    "Currency": "EUR",
    "Capital": "Mamoudzou",
    "Continent": "AF"
}, {
    "Iso2": "UM",
    "Name": "United States Minor Outlying Islands",
    "Iso3": "UMI",
    "Unicode": "🇺🇲",
    "Dial": "1",
    "Currency": "USD",
    "Capital": "",
    "Continent": "OC"
}, {
    "Iso2": "LB",
    "Name": "Lebanon",
    "Iso3": "LBN",
    "Unicode": "🇱🇧",
    "Dial": "961",
    "Currency": "LBP",
    "Capital": "Beirut",
    "Continent": "AS"
}, {
    "Iso2": "LC",
    "Name": "Saint Lucia",
    "Iso3": "LCA",
    "Unicode": "🇱🇨",
    "Dial": "1758",
    "Currency": "XCD",
    "Capital": "Castries",
    "Continent": "NA"
}, {
    "Iso2": "LA",
    "Name": "Laos",
    "Iso3": "LAO",
    "Unicode": "🇱🇦",
    "Dial": "856",
    "Currency": "LAK",
    "Capital": "Vientiane",
    "Continent": "AS"
}, {
    "Iso2": "TV",
    "Name": "Tuvalu",
    "Iso3": "TUV",
    "Unicode": "🇹🇻",
    "Dial": "688",
    "Currency": "AUD",
    "Capital": "Funafuti",
    "Continent": "OC"
}, {
    "Iso2": "TW",
    "Name": "Taiwan",
    "Iso3": "TWN",
    "Unicode": "🇹🇼",
    "Dial": "886",
    "Currency": "TWD",
    "Capital": "Taipei",
    "Continent": "AS"
}, {
    "Iso2": "TT",
    "Name": "Trinidad and Tobago",
    "Iso3": "TTO",
    "Unicode": "🇹🇹",
    "Dial": "1868",
    "Currency": "TTD",
    "Capital": "Port of Spain",
    "Continent": "NA"
}, {
    "Iso2": "TR",
    "Name": "Turkey",
    "Iso3": "TUR",
    "Unicode": "🇹🇷",
    "Dial": "90",
    "Currency": "TRY",
    "Capital": "Ankara",
    "Continent": "AS"
}, {
    "Iso2": "LK",
    "Name": "Sri Lanka",
    "Iso3": "LKA",
    "Unicode": "🇱🇰",
    "Dial": "94",
    "Currency": "LKR",
    "Capital": "Colombo",
    "Continent": "AS"
}, {
    "Iso2": "LI",
    "Name": "Liechtenstein",
    "Iso3": "LIE",
    "Unicode": "🇱🇮",
    "Dial": "423",
    "Currency": "CHF",
    "Capital": "Vaduz",
    "Continent": "EU"
}, {
    "Iso2": "LV",
    "Name": "Latvia",
    "Iso3": "LVA",
    "Unicode": "🇱🇻",
    "Dial": "371",
    "Currency": "EUR",
    "Capital": "Riga",
    "Continent": "EU"
}, {
    "Iso2": "TO",
    "Name": "Tonga",
    "Iso3": "TON",
    "Unicode": "🇹🇴",
    "Dial": "676",
    "Currency": "TOP",
    "Capital": "Nuku'alofa",
    "Continent": "OC"
}, {
    "Iso2": "LT",
    "Name": "Lithuania",
    "Iso3": "LTU",
    "Unicode": "🇱🇹",
    "Dial": "370",
    "Currency": "LTL",
    "Capital": "Vilnius",
    "Continent": "EU"
}, {
    "Iso2": "LU",
    "Name": "Luxembourg",
    "Iso3": "LUX",
    "Unicode": "🇱🇺",
    "Dial": "352",
    "Currency": "EUR",
    "Capital": "Luxembourg",
    "Continent": "EU"
}, {
    "Iso2": "LR",
    "Name": "Liberia",
    "Iso3": "LBR",
    "Unicode": "🇱🇷",
    "Dial": "231",
    "Currency": "LRD",
    "Capital": "Monrovia",
    "Continent": "AF"
}, {
    "Iso2": "LS",
    "Name": "Lesotho",
    "Iso3": "LSO",
    "Unicode": "🇱🇸",
    "Dial": "266",
    "Currency": "LSL",
    "Capital": "Maseru",
    "Continent": "AF"
}, {
    "Iso2": "TH",
    "Name": "Thailand",
    "Iso3": "THA",
    "Unicode": "🇹🇭",
    "Dial": "66",
    "Currency": "THB",
    "Capital": "Bangkok",
    "Continent": "AS"
}, {
    "Iso2": "TG",
    "Name": "Togo",
    "Iso3": "TGO",
    "Unicode": "🇹🇬",
    "Dial": "228",
    "Currency": "XOF",
    "Capital": "Lome",
    "Continent": "AF"
}, {
    "Iso2": "TD",
    "Name": "Chad",
    "Iso3": "TCD",
    "Unicode": "🇹🇩",
    "Dial": "235",
    "Currency": "XAF",
    "Capital": "N'Djamena",
    "Continent": "AF"
}, {
    "Iso2": "TC",
    "Name": "Turks and Caicos Islands",
    "Iso3": "TCA",
    "Unicode": "🇹🇨",
    "Dial": "1649",
    "Currency": "USD",
    "Capital": "Cockburn Town",
    "Continent": "NA"
}, {
    "Iso2": "LY",
    "Name": "Libya",
    "Iso3": "LBY",
    "Unicode": "🇱🇾",
    "Dial": "218",
    "Currency": "LYD",
    "Capital": "Tripolis",
    "Continent": "AF"
}, {
    "Iso2": "VA",
    "Name": "Vatican",
    "Iso3": "VAT",
    "Unicode": "🇻🇦",
    "Dial": "379",
    "Currency": "EUR",
    "Capital": "Vatican City",
    "Continent": "EU"
}, {
    "Iso2": "VC",
    "Name": "Saint Vincent and the Grenadines",
    "Iso3": "VCT",
    "Unicode": "🇻🇨",
    "Dial": "1784",
    "Currency": "XCD",
    "Capital": "Kingstown",
    "Continent": "NA"
}, {
    "Iso2": "AE",
    "Name": "United Arab Emirates",
    "Iso3": "ARE",
    "Unicode": "🇦🇪",
    "Dial": "971",
    "Currency": "AED",
    "Capital": "Abu Dhabi",
    "Continent": "AS"
}, {
    "Iso2": "AD",
    "Name": "Andorra",
    "Iso3": "AND",
    "Unicode": "🇦🇩",
    "Dial": "376",
    "Currency": "EUR",
    "Capital": "Andorra la Vella",
    "Continent": "EU"
}, {
    "Iso2": "AG",
    "Name": "Antigua and Barbuda",
    "Iso3": "ATG",
    "Unicode": "🇦🇬",
    "Dial": "1268",
    "Currency": "XCD",
    "Capital": "St. John's",
    "Continent": "NA"
}, {
    "Iso2": "AF",
    "Name": "Afghanistan",
    "Iso3": "AFG",
    "Unicode": "🇦🇫",
    "Dial": "93",
    "Currency": "AFN",
    "Capital": "Kabul",
    "Continent": "AS"
}, {
    "Iso2": "AI",
    "Name": "Anguilla",
    "Iso3": "AIA",
    "Unicode": "🇦🇮",
    "Dial": "1264",
    "Currency": "XCD",
    "Capital": "The Valley",
    "Continent": "NA"
}, {
    "Iso2": "VI",
    "Name": "U.S. Virgin Islands",
    "Iso3": "VIR",
    "Unicode": "🇻🇮",
    "Dial": "1340",
    "Currency": "USD",
    "Capital": "Charlotte Amalie",
    "Continent": "NA"
}, {
    "Iso2": "IS",
    "Name": "Iceland",
    "Iso3": "ISL",
    "Unicode": "🇮🇸",
    "Dial": "354",
    "Currency": "ISK",
    "Capital": "Reykjavik",
    "Continent": "EU"
}, {
    "Iso2": "IR",
    "Name": "Iran",
    "Iso3": "IRN",
    "Unicode": "🇮🇷",
    "Dial": "98",
    "Currency": "IRR",
    "Capital": "Tehran",
    "Continent": "AS"
}, {
    "Iso2": "AM",
    "Name": "Armenia",
    "Iso3": "ARM",
    "Unicode": "🇦🇲",
    "Dial": "374",
    "Currency": "AMD",
    "Capital": "Yerevan",
    "Continent": "AS"
}, {
    "Iso2": "AL",
    "Name": "Albania",
    "Iso3": "ALB",
    "Unicode": "🇦🇱",
    "Dial": "355",
    "Currency": "ALL",
    "Capital": "Tirana",
    "Continent": "EU"
}, {
    "Iso2": "AO",
    "Name": "Angola",
    "Iso3": "AGO",
    "Unicode": "🇦🇴",
    "Dial": "244",
    "Currency": "AOA",
    "Capital": "Luanda",
    "Continent": "AF"
}, {
    "Iso2": "AS",
    "Name": "American Samoa",
    "Iso3": "ASM",
    "Unicode": "🇦🇸",
    "Dial": "1684",
    "Currency": "USD",
    "Capital": "Pago Pago",
    "Continent": "OC"
}, {
    "Iso2": "AR",
    "Name": "Argentina",
    "Iso3": "ARG",
    "Unicode": "🇦🇷",
    "Dial": "54",
    "Currency": "ARS",
    "Capital": "Buenos Aires",
    "Continent": "SA"
}, {
    "Iso2": "AU",
    "Name": "Australia",
    "Iso3": "AUS",
    "Unicode": "🇦🇺",
    "Dial": "61",
    "Currency": "AUD",
    "Capital": "Canberra",
    "Continent": "OC"
}, {
    "Iso2": "AT",
    "Name": "Austria",
    "Iso3": "AUT",
    "Unicode": "🇦🇹",
    "Dial": "43",
    "Currency": "EUR",
    "Capital": "Vienna",
    "Continent": "EU"
}, {
    "Iso2": "AW",
    "Name": "Aruba",
    "Iso3": "ABW",
    "Unicode": "🇦🇼",
    "Dial": "297",
    "Currency": "AWG",
    "Capital": "Oranjestad",
    "Continent": "NA"
}, {
    "Iso2": "IN",
    "Name": "India",
    "Iso3": "IND",
    "Unicode": "🇮🇳",
    "Dial": "91",
    "Currency": "INR",
    "Capital": "New Delhi",
    "Continent": "AS"
}, {
    "Iso2": "AX",
    "Name": "Aland Islands",
    "Iso3": "ALA",
    "Unicode": "🇦🇽",
    "Dial": "35818",
    "Currency": "EUR",
    "Capital": "Mariehamn",
    "Continent": "EU"
}, {
    "Iso2": "AZ",
    "Name": "Azerbaijan",
    "Iso3": "AZE",
    "Unicode": "🇦🇿",
    "Dial": "994",
    "Currency": "AZN",
    "Capital": "Baku",
    "Continent": "AS"
}, {
    "Iso2": "IE",
    "Name": "Ireland",
    "Iso3": "IRL",
    "Unicode": "🇮🇪",
    "Dial": "353",
    "Currency": "EUR",
    "Capital": "Dublin",
    "Continent": "EU"
}, {
    "Iso2": "ID",
    "Name": "Indonesia",
    "Iso3": "IDN",
    "Unicode": "🇮🇩",
    "Dial": "62",
    "Currency": "IDR",
    "Capital": "Jakarta",
    "Continent": "AS"
}, {
    "Iso2": "UA",
    "Name": "Ukraine",
    "Iso3": "UKR",
    "Unicode": "🇺🇦",
    "Dial": "380",
    "Currency": "UAH",
    "Capital": "Kiev",
    "Continent": "EU"
}, {
    "Iso2": "QA",
    "Name": "Qatar",
    "Iso3": "QAT",
    "Unicode": "🇶🇦",
    "Dial": "974",
    "Currency": "QAR",
    "Capital": "Doha",
    "Continent": "AS"
}, {
    "Iso2": "MZ",
    "Name": "Mozambique",
    "Iso3": "MOZ",
    "Unicode": "🇲🇿",
    "Dial": "258",
    "Currency": "MZN",
    "Capital": "Maputo",
    "Continent": "AF"
}]
