function tagging(person){

    let extras = {}

    extras =  {
        'widget': person.businessGaTag?person.businessGaTag:'whatapp',
        'agent': person.gaTag?person.gaTag : person.id
    }

    if (typeof window.fbq === 'function') {
        //console.log("fb")
        window.fbq('trackCustom', 'chatWithEvent', extras);
    }

    if (typeof window.gtag === "function") {
        //console.log("GA 1")
        window.gtag('event', 'chatwith', {
            'event_category': person.businessGaTag?person.businessGaTag:'whatapp',
            'event_label': person.gaTag?person.gaTag : person.id
        });
    }
}

export { tagging };
