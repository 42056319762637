<script>
  import {slide} from "svelte/transition";
  import Form from "../components/Form.svelte";
  import {fade, fly} from "svelte/transition";

  import WidgetHeader from "../components/WidgetHeader.svelte";
  import CountDown from "../components/CountDown.svelte";
  import Mainbutton from "../components/Mainbutton.svelte";
  import Person from "../components/Person.svelte";
  import People from "../components/People.svelte";
  import {personHasFormData, send, sendform} from "../utils/sendwhatsapp";
  import Faqs from "../components/Faqs.svelte";
  import NewFaqs from "../components/NewFaqs.svelte";
  import {getLocalStorage} from "../utils/localStorage";
  import LeaveMess from "../components/LeaveMessage.svelte";
  import Closer from "../components/Closer.svelte";
  import Chat from "../components/chat/Chat.svelte";
  import Welcomeback from "../components/Welcomeback.svelte";
  import Banner from "../components/Banner.svelte";
  import VideoSplash from "./VideoSplash.svelte";
  import {isReturnUser} from "../utils/localStorage";

  export let data;
  export let openWidget;

  let up = data.isopen;

  let once = false;

  let videoSplashShowed = false, videoSplashOpen = false;
  let classWidget = "chatwith-widget-show";


  $: if (data.openFirstAvailable === true) {
    const person = data.whatsapps.find(
            (obj) => obj.online === true && obj.form !== null
    );
    if (person) {
      if(once === false){
        once = true
        callme(person);
      }

    }
  }

  $: if (openWidget !== false) {
    up = openWidget;
  }

  $: cssStyles = 'z-index:' + data.zIndex + ';bottom:' + data.yAxis+'px;'+ (data.rightpos == 0?'left:' + data.xAxis + 'px;':'right:' + data.xAxis + 'px;');

  let showform = false;
  let showfaqs = false;
  let formitems = {items: []};
  let activePerson;
  let showall = false;
  let showWelcomeMessage = false;
  let leaveamessage = false;

  function close() {
    showform = false;
  }

  function sf(event) {
    sendform(activePerson, event);
  }

  function showFaqs() {
    if (activePerson.faqGrps.length > 0) {
      data.activeFaqs = activePerson.faqGrps[0];
      showfaqs = true;
      return true;
    } else if (data.faqGrps.length > 0) {
      data.activeFaqs = data.faqGrps[0];
      showfaqs = true;
      return true;
    }
    showfaqs = false;
    return false;
  }

  function problemSolved() {
    activePerson = null;
    showform = false;
    showfaqs = false;
  }

  function problemNotSolved() {
    const hasForm = personHasFormData(activePerson);
    setShowWelcomeMessage("form");

    //if there is no form and not online - nothing
    if (hasForm === false && activePerson.online === false) {
      return;
    }
    //send the whatsapp
    else if (hasForm === false) {
      send(activePerson, {});
    } else {
      showform = true;
      showfaqs = false;
      formitems = activePerson.form;
    }
  }

  function callme(person, inputdata = {}) {
    activePerson = person;
    if (showFaqs()) {
      return;
    }

    const hasForm = personHasFormData(person);

    //if there is no form and not online - nothing
    leaveamessage = false;
    if (
            hasForm === false &&
            ((person.online === false && data.offlineLeads === true) ||
                    (data.desktopLeads === true && window.innerWidth > 1000))
    ) {
      leaveamessage = true;
      return;
    }
    //send the whatsapp
    else if (hasForm === false) {
      if (activePerson.online === true) {
        send(person, inputdata);
      }
    } else {
      setShowWelcomeMessage("form");
      showform = true;
      formitems = person.form;
    }
  }

  function handleClick() {
    //console.log(data);
    up = !up;
    if(up && !videoSplashShowed && data.videoSplashUrlActivated === true && data.videoSplashUrl !== ''){
      videoSplashShowed = true;
      videoSplashOpen = true;
      classWidget = "chatwith-widget-hide";
    }else{
      videoSplashOpen = false;
      classWidget = "chatwith-widget-show";
    }
  }

  function videoClose() {
    videoSplashOpen = false;
    classWidget = "chatwith-widget-show";
  }

  function LeaveMessage() {
    setShowWelcomeMessage("leaveMessage");
  }

  function setShowWelcomeMessage(id) {
    const key = `${activePerson.id}${id}`;
    if (getLocalStorage(key)) {
      setTimeout(() => {
        showWelcomeMessage = true;
      }, 500);
      setTimeout(() => {
        showWelcomeMessage = false;
      }, 3500);
    }
  }

</script>

<div
  style={cssStyles}
  hidden={data.active != 1}
  class="chatwith device-{data.device} {data.rightpos == 0
    ? 'leftx'
    : 'rightx chatwith-widget'}"
>
  {#if up === true}
    <div  style="max-width: 260px;" class={classWidget}>
      <Welcomeback message="{data.WelcomeBackMessage}" data/>

      <div
        style="border-radius: {data.rounding}px"
        class="chatwith-bubble {data.rightpos == 0
          ? 'slide-in-left'
          : 'slide-in-right'}"
      >
        {#if data.noHeader !== true}
          <WidgetHeader on:close={handleClick} {data} {showall} />
        {/if}



        {#if leaveamessage === true}
          <div in:fade>
            <div class="person">
              <Person person={activePerson} />
            </div>
            <div style="position: relative;background: #fff">
              <Closer on:closeIt={() => (leaveamessage = false)} />
              <div style="padding:10px">
                <LeaveMess {data} person={activePerson} bgcolor={data.color} />
              </div>
            </div>
          </div>
        {:else if showfaqs}
          <div in:fade>
          <div class="person">
            <Person person={activePerson} />
          </div>
          {#if data.faqDisplay === true}
          <NewFaqs
            on:leave-message={LeaveMessage}
            on:problem-solved={problemSolved}
            on:problem-not-solved={problemNotSolved}
            bgcolor={data.color}
            faqs={data.activeFaqs}
            {activePerson}
            {data}
          />
          {:else}
          <Faqs
            on:leave-message={LeaveMessage}
            on:problem-solved={problemSolved}
            on:problem-not-solved={problemNotSolved}
            bgcolor={data.color}
            faqs={data.activeFaqs}
            {activePerson}
            {data}
          />
          {/if}

          </div>

          {:else if showform && activePerson.chatform === true}
          <div in:fade>
            <div class="person">
              <Person person={activePerson} />
            </div>

            <Chat {data} {activePerson} on:close={() => {showform=false}}  />
          </div>

        {:else if showform}
          <div in:fade>
            <div class="person">
              <Person person={activePerson} />
            </div>
            <div style="position: relative">
              <Form
                on:callmex={sf}
                on:close={close}
                {data}
                person={activePerson}
                {formitems}
                {showWelcomeMessage}
                hidden={showform == false}
              />
            </div>
          </div>
        {:else}
          <div in:fade>
          {#if data.countDownEnabled === true}
            <CountDown {data} />
          {/if}

            <Banner {data} isReturnUser={isReturnUser()}/>
            <div hidden={showform == true} class="people">
              <People on:message={v => {callme(v.detail);}} {data}{showall}/>
              {#if data.whatsapps.length > 9}
                <button
                        on:click={() => (showall = !showall)}
                        style="background:{data.color}"
                        class="showmore"
                >
                  {#if showall === false}
                    {#if data.showAllAgents}{data.showAllAgents}{:else}Show all
                      agents{/if} ({data.whatsapps.length})
                  {:else if data.showLessAgents}{data.showLessAgents}{:else}Show
                    less agents{/if}
                </button>
              {/if}
            </div>

          </div>
        {/if}

        {#if data.premium !== true}
          <div class="powered-by-tochatbe">
            <a href="https://tochat.be/" title="Free WhatsApp Widget">Powered by ToChat.be</a>
          </div>
        {/if}
      </div>
    </div>
  {/if}
  {#if videoSplashOpen === true}
  <div class="video-splash" style="border-radius: {data.rounding}px">
    <VideoSplash on:close={videoClose} {data}/>
  </div>
  {/if}
  <div
    class="mbutton  {data.rightpos == 0
      ? data.animation + '-in-left'
      : data.animation + '-in-right'}  "
  >
    <Mainbutton on:close={handleClick} {data} />
  </div>
</div>

<style>
  .chatwith {
    position: fixed;
    bottom: 10px;
    font-family: Arial, sans-serif;
  }

  .chatwith.rightx {
    right: 10px;
  }

  .chatwith.leftx {
    left: 10px;
  }

  .chatwith-widget-show{
    opacity: 1;
  }

  .chatwith-widget-hide{
    opacity: 0;
  }

  .chatwith .chatwith-bubble {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    min-width: 240px;
    width: 260px;
    background: #fff;
  }

  .chatwith .peopleScroll {
    overflow-y: scroll;
    max-height: 320px;
  }

  .chatwith .people {
    background: white;
  }

  .chatwith .person {
    border-bottom: #efefef 1px solid;
    padding: 10px 0;
    background: #fff;
  }

  .chatwith .person:last-child {
    border-bottom: #fff 1px solid;
  }

  .chatwith.rightx .mbutton {
    text-align: right;
  }

  .chatwith .slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .chatwith .slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .chatwith .bounce-in-right {
    -webkit-animation: bounce-in-right 1.1s both;
    animation: bounce-in-right 1.1s both;
  }

  .chatwith .bounce-in-left {
    -webkit-animation: bounce-in-left 1.1s both;
    animation: bounce-in-left 1.1s both;
  }

  .chatwith .swing-in-bottom-fwd {
    -webkit-animation: swing-in-bottom-fwd 0.5s
      cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation: swing-in-bottom-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
  }

  .chatwith .showmore {
    width: 100%;
    margin: auto;
    color: white;
    font-size: 14px;
    border: none;
  }
  .chatwith .video-splash{
    margin-bottom:10px;
    background: black;
    margin-left: 35px;
    width:225px;
    height:400px;
  }
  .welcome-back {
    text-align: right;
    background: #2b70cd;
    float: right;
    padding: 15px 42px;
    margin: 10px;
    font-size: 19px;
    color: #fff;
    border-radius: 6px;
    max-width: 200px;
  }

  .powered-by-tochatbe {
    border-top: 1px solid #efefef;
    padding-inline-end: 15px;
    padding-block-end: 3px;
    text-align: right;
  }

  .powered-by-tochatbe a,
  .powered-by-tochatbe a:active,
  .powered-by-tochatbe a:visited {
    color: #dfdcdc;
    text-decoration: none;
    font-size: 0.8rem;
  }

  @media only screen and (min-width: 769px) {
    .device-M {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .device-D {
      display: none;
    }
  }

  /* ----------------------------------------------
    * Generated by Animista on 2021-1-18 10:28:32
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info.
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation swing-in-bottom-fwd
     * ----------------------------------------
     */
  @-webkit-keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
      transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 1;
    }
  }

  @keyframes swing-in-bottom-fwd {
    0% {
      -webkit-transform: rotateX(100deg);
      transform: rotateX(100deg);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      -webkit-transform-origin: bottom;
      transform-origin: bottom;
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-1-18 10:32:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation slide-in-right
     * ----------------------------------------
     */
  @-webkit-keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(1000px);
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-3-8 13:17:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation slide-in-left
     * ----------------------------------------
     */
  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-1-18 10:40:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation bounce-in-right
     * ----------------------------------------
     */
  @-webkit-keyframes bounce-in-right {
    0% {
      -webkit-transform: translateX(600px);
      transform: translateX(600px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateX(68px);
      transform: translateX(68px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateX(32px);
      transform: translateX(32px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateX(8px);
      transform: translateX(8px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @keyframes bounce-in-right {
    0% {
      -webkit-transform: translateX(600px);
      transform: translateX(600px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateX(68px);
      transform: translateX(68px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateX(32px);
      transform: translateX(32px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateX(8px);
      transform: translateX(8px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  .slide-in-bottom {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-3-8 13:20:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation bounce-in-left
     * ----------------------------------------
     */
  @-webkit-keyframes bounce-in-left {
    0% {
      -webkit-transform: translateX(-600px);
      transform: translateX(-600px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateX(-68px);
      transform: translateX(-68px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateX(-28px);
      transform: translateX(-28px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateX(-8px);
      transform: translateX(-8px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @keyframes bounce-in-left {
    0% {
      -webkit-transform: translateX(-600px);
      transform: translateX(-600px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateX(-68px);
      transform: translateX(-68px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateX(-28px);
      transform: translateX(-28px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateX(-8px);
      transform: translateX(-8px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-2-9 12:3:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
     * ----------------------------------------
     * animation slide-in-bottom
     * ----------------------------------------
     */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
