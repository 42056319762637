<script>
  export let data;
  export let item;
  export let value;
  export let index;
  
  let isError = false;
  let err = '';

  export const validate = () => {
    if (item.required && ( item.val === ""|| (item.type == 'tel' && item.telNum === '') )) {
      err = data.requiredValidationText?data.requiredValidationText:'This field is required';
      if(value.findIndex(c=>c.index === index) === -1)
        value.push({'index': index});   
      isError = true;
    } else if (item.type === 'tel' && item.telNum !== '' && !/^\d{6,15}$/.test(item.telNum)) {
      err = data.telValidationText?data.telValidationText:'This is not a phone number.';
      if(value.findIndex(c=>c.index === index) === -1)
        value.push({'index': index});   
      isError = true;
    } else if (item.type === 'email' && item.val !== '' && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(item.val)) {
      err = data.emailValidationText?data.emailValidationText:'Sorry but this email is not valid.';
      if(value.findIndex(c=>c.index === index) === -1)
        value.push({'index': index});   
      isError = true;
    } else {      
      if(value.findIndex(c=>c.index === index) > -1)
        value.splice(value.findIndex(c=>c.index === index));  
      isError = false;      
    }
  };  
</script>
{#if isError}
  <div class="chatwith-box-error">
    {err}
  </div>
{/if}
<style>
  .chatwith-box-error {
    padding: 3px;
    background: darkred;
    color: white;
    font-weight: bold;
    margin-top: 3px;
    border-radius: 5px;
  }
</style>
