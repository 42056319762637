<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	let video;
	let currentTime = 0;
	let duration;

	let classVolumeOn = "chatwith-volume-show";
	let classVolumeOff = "chatwith-volume-hide";
	let muted = false;


	export let data;

	let timer = setInterval(() => {
		if(duration) video.play();
	}, 300);

	const switchVolume = () =>{
		muted = !muted;
		[classVolumeOn, classVolumeOff] = [classVolumeOff, classVolumeOn];
	};

	const repeat = () => {
		currentTime = 0;
		video.play();
	}

	const close = () => {
		clearInterval(timer);
		dispatch("close");
	}

	const  format = seconds => {
		if (isNaN(seconds)) return '...';

		const minutes = Math.floor(seconds / 60);
		seconds = Math.floor(seconds % 60);
		if (seconds < 10) seconds = '0' + seconds;

		return `${minutes}:${seconds}`;
	}
	function handleMove(e) {
		if (!duration) return; // video not loaded yet
		if (e.type !== 'touchmove' && !(e.buttons & 1)) return; // mouse not down

		const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
		const { left, right } = this.getBoundingClientRect();
		currentTime = duration * (clientX - left) / (right - left);
	}

</script>

<div class="chatwith-video"  >
	<div class="chatwith-video-controls" style="opacity: {duration ? 1 : 0}">
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="28" height="28"
			on:click="{() => repeat()}"
			viewBox="0 0 24 24"
			transform="scale(-1 -1)"
			style=" fill:#ffffff;">
			<path d="M 2 2 L 4.9394531 4.9394531 C 3.1262684 6.7482143 2 9.2427079 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 6.486 17.514 2 12 2 L 12 4 C 16.411 4 20 7.589 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 9.7940092 4.9004767 7.7972757 6.3496094 6.3496094 L 9 9 L 9 2 L 2 2 z"></path>
		</svg>
		<div class="chatwith-video-info">
			<progress value="{(currentTime / duration) || 0}"/>
			<span class="time">{format(currentTime)} / {format(duration)}</span>
		</div>
		<svg xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			width="28" height="28"
			on:click="{() => switchVolume()}"
			viewBox="0 0 518.518 518.518"
			style="enable-background:new 0 0 518.518 518.518;fill:#ffffff;">
			<path d="M253.674,80.899L90.27,197.638H26.392C11.857,197.638,0,209.495,0,224.03v70.457c0,14.534,11.857,26.392,26.392,26.392
				H90.27l163.557,116.739c11.857,8.491,21.419,3.52,21.419-11.016V91.915C275.246,77.38,265.607,72.407,253.674,80.899z"/>
			<path  class={classVolumeOn}  d="M342.338,81.97c-12.164,0-22.033,9.869-22.033,22.032s9.869,22.032,22.033,22.032c72.98,0,132.115,59.135,132.115,132.116
				c0,72.981-59.135,132.115-132.115,132.115c-12.164,0-22.033,9.869-22.033,22.032s9.869,22.032,22.033,22.032
				c97.307,0,176.18-78.872,176.18-176.18C518.518,160.918,439.723,81.97,342.338,81.97z"/>
			<path  class={classVolumeOn}  d="M342.338,313.153c-12.164,0-22.033,9.868-22.033,22.031c0,12.164,9.869,22.032,22.033,22.032
				c54.773,0,99.066-44.37,99.066-99.067c0-54.698-44.369-99.067-99.066-99.067c-12.164,0-22.033,9.868-22.033,22.032
				s9.869,22.032,22.033,22.032c30.369,0,55.08,24.633,55.08,55.08C397.418,288.673,372.785,313.153,342.338,313.153z"/>

			<path class={classVolumeOff} d="M452.115,260.329l62.5-62.501c5.201-5.202,5.201-13.846,0-19.048l-12.623-12.623c-5.201-5.202-13.846-5.202-19.049,0
			l-62.5,62.5l-62.041-62.042c-5.201-5.202-13.846-5.202-19.049,0l-12.623,12.623c-5.201,5.202-5.201,13.847,0,19.048l62.043,62.042
			L326.73,322.37c-5.201,5.202-5.201,13.847,0,19.049l12.623,12.622c5.203,5.202,13.848,5.202,19.049,0L420.443,292l62.041,62.041
			c5.203,5.202,13.848,5.202,19.049,0l12.623-12.622c5.201-5.202,5.201-13.847,0-19.049L452.115,260.329z"/>
		</svg>
	</div>
	<video
		src={data.videoSplashUrl}
		bind:currentTime
		bind:duration
		bind:muted
		bind:this={video}
		on:mousemove={handleMove}
		style="border-radius: {data.rounding}px;"
		playsinline
	>
		<track kind="captions"/>
	</video>


	<div class="chatwith-video-close" style="opacity: {duration ? 1 : 0}">
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
			width="24" height="24"
			on:click="{() => close()}"
			viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;fill:#ffffff;">
			<path id="XMLID_29_" d="M165,0C120.926,0,79.492,17.163,48.328,48.327c-64.334,64.333-64.334,169.011-0.002,233.345
			C79.49,312.837,120.926,330,165,330c44.072,0,85.508-17.163,116.672-48.328c64.334-64.334,64.334-169.012,0-233.345
			C250.508,17.163,209.072,0,165,0z M239.246,239.245c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
			L165,186.213l-53.033,53.033c-2.93,2.929-6.768,4.394-10.607,4.394c-3.838,0-7.678-1.465-10.605-4.394
			c-5.859-5.857-5.859-15.355,0-21.213L143.787,165l-53.033-53.033c-5.859-5.857-5.859-15.355,0-21.213
			c5.857-5.857,15.355-5.857,21.213,0L165,143.787l53.031-53.033c5.857-5.857,15.355-5.857,21.213,0
			c5.859,5.857,5.859,15.355,0,21.213L186.213,165l53.033,53.032C245.104,223.89,245.104,233.388,239.246,239.245z"/>
		</svg>
	</div>
</div>

<style>
	.chatwith-video{
		position:relative;
		justify-content: center;
    	display: flex;
	}

	.chatwith-video video {
		top: 0;
		width: 225px;
		height:400px;
		bottom:0;
		left:0;
		right:0;
		position:absolute;
		object-fit: cover;
	}
	.chatwith-video-controls {
		width: 100%;
		transition: opacity 1s;
		display: flex;
		margin: 30px 20px;
		z-index: 999;
	}

	.chatwith-video-controls svg:first-child,
	.chatwith-video-controls svg:last-child{
		padding-left: 10px;
		cursor:pointer;
	}

	.chatwith-video-info {
		width: 100%;
		text-align: right;
	}

	.chatwith-video-info span {
		padding: 0.2em 0.5em;
		color: white;
		font-size: 0.8em;
		user-select: none;
	}
	.chatwith-video-info progress {
		width: 100%;
    	height: 8px;
	}

	.chatwith-video-info progress::-webkit-progress-bar {
		background-color: rgba(0,0,0,0.2);
	}

	.chatwith-video-info progress::-webkit-progress-value {
		background-color: rgba(255,255,255,0.6);
	}
	.chatwith-volume-show{
		display:block;
	}
	.chatwith-volume-hide{
		display:none;
	}
	.chatwith-video-close{
		top: -8px;
		right: -8px;
		z-index: 999;
		position: absolute;
		cursor:pointer;
		transition: opacity 1s;
	}


</style>
