import {Base64} from "js-base64";
import { tagging } from "./tagging";
import {dataAttributes} from "./getdata";
import {isReturnUser, usedToChat} from "./localStorage";



let host;

function setHost(h) {
  host = h;
}

function sendform(person, event) {
  let str = JSON.stringify(event.detail.data);
  str = Base64.encodeURI(str);
  if(event.detail.url && event.detail.url !== null){
    send(person, str, event.detail.url);
  }
  else{
    send(person, str);
  }
}


function send(person, inputdata = "",buyUrl = null) {

  //facbook and google tags
  tagging(person);

  //Record the user to local storage if allowed
  usedToChat()

  let extra = '&url='+encodeURIComponent(window.location.href)

  if (dataAttributes.product) {
    extra = extra + '&product='+encodeURIComponent(dataAttributes.product);
  }

  if (dataAttributes.price) {
    extra = extra + '&price='+encodeURIComponent(dataAttributes.price);
  }

  if (dataAttributes.fullMessage) {
    extra = extra + '&fullmessage='+encodeURIComponent(dataAttributes.fullMessage);
  }
  let u = ''
  if(buyUrl){
    u="&buyUrl="+encodeURIComponent(buyUrl);
  }

  window.open(host + "/api/business/send/" + person.id + "?data=" + inputdata + extra + u); //if there is no form and not online - nothing
}

function personHasFormData(person) {
  let hasForm = false;
  if (person.form != null && typeof person.form === "object") {
    hasForm =
      Object.keys(person.form).length > 0 && person.form.constructor === Object;
  }
  return hasForm;
}

export { personHasFormData, sendform, send, setHost };
