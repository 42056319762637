import axios from "axios";
import { DateTime as Dtime } from "luxon";
import {encodeURL} from "js-base64";

const dataAttributes = {
  product:null,
  price:null,
  fullMessage:null
};

function changeTimezone(date, tz) {

  if(tz === null){
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  // suppose the date is 12:00 UTC
  const invdate = new Date(date.toLocaleString('en-US', {
    timeZone: tz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

function checkHolidays(whatsapp) {

  const curentdatetime = new Date().getTime() ;

  let from = new Date(whatsapp.holidayFrom);
  from = changeTimezone(from, whatsapp.holidayTimezone);

  let until = new Date(whatsapp.holidayUntil);
  until = changeTimezone(until, whatsapp.holidayTimezone);
  //May the end date inclusive
  until = until.getTime() + 86400000

  if(curentdatetime >= from.getTime()  && curentdatetime <= until ){
    return false
  }
  return true

}

function shuffle(array) {
  let counter = array.length;
  while (counter > 0) {
    let index = Math.floor(Math.random() * counter);
    counter--;
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }
  return array;
}

function getPersonId() {
  if (document.currentScript.hasAttribute("data-person")) {
    return document.currentScript.getAttribute("data-person");
  }

  return null;
}

function getkey() {
  let key = "";
  if (document.currentScript.hasAttribute("data-key")) {
    key = document.currentScript.getAttribute("data-key");
  } else if (document.currentScript.hasAttribute("key")) {
    key = document.currentScript.getAttribute("key");
  } else {
    const queryString = document.currentScript
      .getAttribute("src")
      .split("key=");
    key = queryString[1];
  }
  return key;
}

function setAttributes(){

  if (document.currentScript.hasAttribute("data-product")) {
    dataAttributes.product =  document.currentScript.getAttribute("data-product");
  }

  if (document.currentScript.hasAttribute("data-price")) {
    dataAttributes.price = document.currentScript.getAttribute("data-price");
  }

  if (document.currentScript.hasAttribute("data-fullmessage")) {
    dataAttributes.fullMessage = document.currentScript.getAttribute("data-fullmessage");
  }

}

async function gData(host) {
  //console.log(host)
  setAttributes()



  const sericesurl = host + "/api/business/get/" + getkey();
  let data = {};
  await axios
    .get(sericesurl)
    .then(function (response) {
      data = response.data.data;
      data.premium = response.data.premium == true
      data.dialingCode = response.data.dialingCode
      const country = response.data.country;
      data.whatsapps.forEach((element, index) => {
        data.whatsapps[index].online = datecompare(element.optimes);
        data.whatsapps[index].businessGaTag = data.gaTag;
      });

      if (data.random === true) {
        data.whatsapps = shuffle(data.whatsapps);
        data.whatsapps = onlyOnline(data.whatsapps);
        const first = data.whatsapps.pop();
        data.whatsapps = [first];
      } else if (data.onlyactive === true) {
        data.whatsapps = onlyOnline(data.whatsapps);
      }

      data.whatsapps.forEach((element, index) => {
        if (data.whatsapps[index].active === true) {
          data.whatsapps[index].active = filterCountry(
            country,
            data.whatsapps[index].countryActivate,
            data.whatsapps[index].country
          );
          //console.log(data.whatsapps);
        }

        if (data.whatsapps[index].active === true && data.whatsapps[index].enableHolidays && data.whatsapps[index].online == true) {
          data.whatsapps[index].online = checkHolidays(data.whatsapps[index])
        }

      });

      if (data.hideIfNoone === true) {
        let someoneonline = false;
        data.whatsapps.forEach((i) =>
          i.online == true ? (someoneonline = true) : ""
        );

        if (someoneonline === false) {
          data.active = false;
        }
      }

      data.whatsapps = data.whatsapps.filter((obj) => obj.active === true);

      data.active = filterCountry(country, data.countryActivate, data.country);

      if (data.whatsapps.length === 0) data.active = false;

      data.faqDisplay = data.alternativeFaq;

      // data.countDownEnabled = true;
      // data.countDownEnds = "2021-01-25T18:43:00+02:00";
      data.countDownFont = "Arial, sans-serif";

    })
    .catch(function (error) {
      console.log(error);
    });

  return data;
}

function onlyOnline(obj) {
  return obj.filter((o) => o.online === true);
}

function filterCountry(userCountry, countryActivate, countries) {
  //console.log(userCountry, countryActivate, countries);
  if (countryActivate == "KO" && countries.includes(userCountry)) {
    return false;
  } else if (countryActivate == "OK" && !countries.includes(userCountry)) {
    return false;
  }
  return true;
}

function datecompare(optimes) {
  if (optimes.length === 0) {
    return true;
  }

  const now = Dtime.local();
  let today = now.toISODate();

  const days = {
    1: "MO",
    2: "TU",
    3: "WE",
    4: "TH",
    5: "FR",
    6: "SA",
    7: "SU",
  };

  const day = now.weekday;

  let localday;

  let online = false;

  for (let i = 0; i < optimes.length; i++) {
    if (optimes[i].timezone) {
      localday = Dtime.local().setZone(optimes[i].timezone, {
        keepLocalTime: false,
      }).weekday;
      today = Dtime.local()
        .setZone(optimes[i].timezone, { keepLocalTime: false })
        .toISODate();
    } else {
      localday = day;
    }

    if (optimes[i].day == days[localday]) {
      //console.log("SAME DAY")

      let start = Dtime.fromISO(today + "T" + optimes[i].availableFrom).diffNow(
        "minutes"
      ).minutes;
      let fin = Dtime.fromISO(today + "T" + optimes[i].availableUntil).diffNow(
        "minutes"
      ).minutes;

      if (optimes[i].timezone) {
        //console.log("TZ")
        let localtoday = Dtime.local()
          .setZone(optimes[i].timezone, { keepLocalTime: false })
          .toISODate();
        start = Dtime.fromISO(localtoday + "T" + optimes[i].availableFrom)
          .setZone(optimes[i].timezone, { keepLocalTime: true })
          .diffNow("minutes").minutes;
        fin = Dtime.fromISO(localtoday + "T" + optimes[i].availableUntil)
          .setZone(optimes[i].timezone, { keepLocalTime: true })
          .diffNow("minutes").minutes;
      }

      if (start < 0 && fin > 0) {
        return true;
      }
    }
  }
  return online;
}

export { gData, getPersonId, dataAttributes };
