<script>
  import { onMount } from "svelte";
  import BigPerson from "./widgets/BigPerson.svelte";
  import Classic from "./widgets/Classic.svelte";
  import { gData, getPersonId } from "./utils/getdata";
  import { setHost } from "./utils/sendwhatsapp";
  import { setCookies } from "./utils/localStorage";
  import { showOnlyOnce } from "./utils/utilities";

  export let widgetType;

  let data = {
    widgetDelay: 0,
    active: 0,
    isopen: false,
    whatsapps: [],
    faqState: "HIDE",
    activeFaqs: []
  };

  let personId = null;

  onMount(async () => {
    personId = getPersonId();
    const url = "process.env.MY_HOST";
    setHost(url);
    data = await gData(url);
    setCookies(!data.turnOffCookies);
    if (data.openOnce === true) {
      data.isopen = showOnlyOnce(data, widgetType);
    }
    if (data.widgetDelay > 0 && data.active) {
      //console.log(data.widgetDelay, data.active);
      data.active = false;
      setTimeout(() => (data.active = true), data.widgetDelay * 1000);
    }
  });
</script>
<div class:chatwithrtl="{data.rtl === true}">
{#if widgetType == "bigperson"}
  <div
    style="display: flex; justify-content: center; flex-flow: wrap row; margin-left: -1em; margin-right: -1em;"
  >
    {#each data.whatsapps as person, i}
      {#if personId === null || personId == person.id}
        <BigPerson {person} {data} />
      {/if}
    {/each}
  </div>
{/if}

{#if widgetType == "classic"}
  <div>
    <Classic openWidget={data.isopen} {data} />
  </div>
{/if}
</div>

<style>
  .chatwithrtl{
    direction: rtl;
    text-align: initial;
  }



</style>
