import App from './App.svelte';
import Cookies from 'js-cookie';


if (document.currentScript.hasAttribute('data-widget-type')) {

	const app = new App({
		target: document.getElementById("whatapp-people-widget"),
		props: {
			widgetType: 'bigperson'
		}
	});
}
else if (document.currentScript.hasAttribute('data-widget-embed')) {

	const app = new App({
		target: document.getElementById("data-widget-embed"),
		props: {
			widgetType: 'classic'
		}
	});
}
else{

	const app = new App({
		target: document.body,
		props: {
			widgetType: 'classic'
		}
	});
}


export default app;
