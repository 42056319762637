<div class="faqs">
    {#if leaveMessage === true}
        <div class="closeit">
            <svg on:click={() => {leaveMessage=false}} style="width: 15px" aria-hidden="true" focusable="false"
                 data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                <path fill="#333"
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
            </svg>
        </div>
        <LeaveMessage {data} person="{activePerson}" bgcolor="{bgcolor}"/>
    {:else}
        <div class="title">{faqs.title}</div>
        <div class="faqs-shortcuts">
            {#each faqs.faqs as faq, i}
                {#if faq.shortcutName !== null }

                    <span on:click={() => {
                        showAll = false;
                        if (faqId === faq.id) {
                          faqId = null;
                          hidden = true;
                        }
                        else {
                          faqId = faq.id;
                          hidden = false;
                        }
                    }} class="faq-shortcut" style="background-color: {getproperty(faq.shortcutName,'color')}">

                            <img class="sml-ico" alt="{faq.shortcutName}" src="{getproperty(faq.shortcutName,'icon')}">
                        {#if faq.translateShortCut}{faq.translateShortCut}{:else}{faq.shortcutName}{/if}
                        </span>
                {/if}
            {/each}
        </div>
        <div class="faqs-content">

            {#each faqs.faqs as faq, i}
                {#if faq.shortcutName }
                    <SingleFaq activeFaq="{(faqId === faq.id && hidden === false) || showAll}" bgcolor="{bgcolor}" faq="{faq}"  allowToggle="{false}"/>
                {:else}
                    <SingleFaq activeFaq="{true}" bgcolor="{bgcolor}" faq="{faq}" allowToggle="{true}"/>
                {/if}
            {/each}

        </div>
        <div class="my-buttons">
            <div on:click={() => {dispatch('problem-solved')}} style="border: 1px solid {bgcolor};color:{bgcolor};background:#fff"
                 class="button">
                {#if faqs.solvedButton}
                    {faqs.solvedButton}
                {:else}
                    faqs.solvedButton
                {/if}
            </div>
            {#if activePerson.online === true}
                <div on:click={() => {dispatch('problem-not-solved')}} style="background:{bgcolor};color:white"
                     class="button">WhatsApp
                </div>
            {:else}
                <div on:click={() => {leaveMessage=true; dispatch('leave-message')}}
                     style="background:{bgcolor};color:white" class="button">
                    {#if (data.leaveMessage)}
                        {data.leaveMessage}
                    {:else}
                        Leave a message
                    {/if}
                </div>
            {/if}
        </div>
    {/if}
</div>

<script>
  import SingleFaq from './SingleFaq.svelte';
  import LeaveMessage from './LeaveMessage.svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let faqs;
  export let activePerson;
  export let bgcolor;
  export let data;

  let faqId = null;
  let hidden = true;
  let activeFaq = false;
  let showAll = false;
  let leaveMessage = false;


  const conf = [
      {
          id: "Return",
          name: "Returns",
          color: "#F2DFC4",
          icon: "https://widget.tochat.be/img/faq-icons/box.png"
      },
      {
          id: "Broken",
          name: "Broken",
          color: "#E8B5B0",
          icon: "https://widget.tochat.be/img/faq-icons/broken-heart.png"
      },
      {
          id: "Discount",
          name: "Returns",
          color: "#D9DBC3",
          icon: "https://widget.tochat.be/img/faq-icons/money-bag.png"
      },
      {
          id: "Delivery",
          name: "Returns",
          color: "#D7EEF6",
          icon: "https://widget.tochat.be/img/faq-icons/broken-heart.png"
      },
  ]

  const  getConf = (id) => conf.find(o => o.id == id)

  const getproperty = (id,type) => {
      const obj = getConf(id)
      if(obj && obj.hasOwnProperty(type)){
          return obj[type]
      }
      else{
          return ""
      }
  }


</script>

<style>
    .faqs {
        max-height: 300px;
        overflow-y: auto;
        padding: 10px;
        background: white;
        color: #5c5b5b;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .faqs-content {
        padding: 5px 5px;
    }
    .button {
        width: 100%;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 10px;
    }
    .my-buttons {
        padding-top: 10px;
        z-index: 1000;
        position: relative;
    }
    .closeit {
        text-align: right;
    }
    .faqs-shortcuts {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
    .faq-shortcut {
        border-radius: 10px;
        margin: 2.5px;
        display: block;
        padding: 10px 10px 12px 10px;
        text-decoration: none;
        font-size: 16px;
        text-transform: capitalize;
        text-align: center;
        color: #010101;
        cursor: pointer;
    }
    .faq-shortcut-default {
        border: 2px solid #2b2d42;
        box-sizing: border-box;
    }
    .sml-ico{
        width: auto;
        height: 15px;
        vertical-align: text-bottom;
    }
</style>
