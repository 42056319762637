<script>
    import {createEventDispatcher} from "svelte";

    export let options;
    $: value = ""

    const dispatch = createEventDispatcher();

    function action(){
        dispatch('message', value);
        value = '';
    }

    const toArray = (data) => {
        return data.split("|");
    };

</script>

<div class="scale-in-center">            
    <select bind:value={value} class="whatapp-select">
    <option value="">--{options.label}--</option>
    {#each toArray(options.data) as option}
        <option value={option}>{option}</option>
    {/each}
    </select>            
    <button on:click={()=>action()} class="whatapp-button">
        <svg style="width: 18px;" aria-hidden="true" focusable="false"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
    </button>           
</div>

<style>
    .whatapp-select{        
        height: 40px;
        display: inline-block;
        margin-bottom: 5px;
        border-radius: 5px;
        flex: 1 1 80%;
    }

    .whatapp-button{
        background: rgb(39, 217, 116);
        color: white;
        border: none;
        height: 40px;
        display: inline-block;
        margin-bottom: 5px;
        border-radius: 5px;        
        margin: 2px;
        flex: 2 1 20%;
    }

    .scale-in-center {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        display: flex;
        animation-delay: 0;
        margin-right: 5px;
        margin-bottom: 5px;

    }

    /* ----------------------------------------------
 * Generated by Animista on 2021-9-9 18:1:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation scale-in-center
     * ----------------------------------------
     */
     @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }
</style>
