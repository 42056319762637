<script>
    import {slide} from "svelte/transition";
    import Person from "../components/Person.svelte";
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();

    export let data;
    export let showall;  

    let len = data.whatsapps.length;
</script>

<div class={showall === true && len > 9 ?"chatwith-peopleScroll-extended":"chatwith-peopleScroll"}>
    {#each data.whatsapps as person, i}
      {#if i < 9 || showall === true}
        {#if len <= 3}
            <div
            transition:slide|local
            class = "chatwith-person"
            on:click={() => dispatch("message", person)}
            >
                <Person {person} />
            </div>
        {:else if len <= 6}
            {#if i % 2 === 0} 
            <div class="chatwith-people-list">
                <div
                transition:slide|local
                class = "chatwith-person-vertical"
                on:click={() => dispatch("message", data.whatsapps[i])}
                >
                    <Person person = {data.whatsapps[i]} isVertical={true} />
                </div>    
                {#if len > i + 1}
                <div
                transition:slide|local
                class = "chatwith-person-vertical"
                on:click={() => dispatch("message", data.whatsapps[i+1])}
                >
                    <Person person = {data.whatsapps[i+1]} isVertical={true} />
                </div> 
                {/if}
            </div>   
            {/if}
        {:else}  
            {#if i % 3 === 0} 
            <div class="chatwith-people-list">
                <div
                transition:slide|local
                class = "chatwith-person-vertical"
                on:click={() => dispatch("message", data.whatsapps[i])}
                >
                    <Person person = {data.whatsapps[i]} isVertical={true} />
                </div> 
                {#if len > i + 1}   
                <div
                transition:slide|local
                class = "chatwith-person-vertical"
                on:click={() => dispatch("message", data.whatsapps[i+1])}
                >
                    <Person person = {data.whatsapps[i+1]} isVertical={true} />
                </div> 
                {/if}
                {#if len > i + 2}
                <div
                transition:slide|local
                class = "chatwith-person-vertical"
                on:click={() => dispatch("message", data.whatsapps[i+2])}
                >
                    <Person person = {data.whatsapps[i+2]} isVertical={true} />
                </div> 
                {/if}
            </div>   
            {/if}
        {/if}
      {/if}
    {/each}
</div>

<style>
  .chatwith-peopleScroll {
    overflow-y: scroll;
    max-height: 320px;
  }  
  .chatwith-peopleScroll-extended {
    overflow-y: scroll;
    max-height: 420px;
  } 
  .chatwith-person {
    padding: 10px;
    background: #fff;
    border-bottom: #efefef 1px solid;
  }
  .chatwith-person-vertical {
    padding-top: 10px;
    background: #fff;
  }
  .chatwith-people-list{
    display: flex;
    justify-content: space-around;
  }

</style>