<script>
  import { createEventDispatcher } from "svelte";
  import FormValidate from "./FormValidate.svelte";
  import TelField from "./TelField.svelte";
  import {
    getLocalStorage,
    setLocalStorage,
    updateVals,
  } from "../utils/localStorage";

  const dispatch = createEventDispatcher();

  let errorList = [];
  let formValidateList = [];

  export let formitems;
  export let data;
  export let noclose;
  export let person;

  formitems.items.forEach((a) => (a.val = ""));
  const personId = `${person.id}form`;
  const perosnForm = getLocalStorage(personId);
  formitems = perosnForm ? updateVals(perosnForm, formitems, person.chatform) : formitems;

  const toArray = (data) => {
    return data.split("|");
  };

  const close = () => {
    dispatch("close");
  };

  const sendtocallme = (url = null) => {
    for(let i in formValidateList)
      try {
        formValidateList[i].validate();
      }
      catch (ex) {
        console.log(ex);
      }
    if (errorList.length == 0) {
      setLocalStorage(personId, formitems);
      dispatch("callmex", {
        data: formitems.items,
        url: url,
      });
    }
  };
</script>

<div class="chatwith-box">
  <div style="padding-top: 20px" class="">
    {#if noclose !== true}
      <div class="close-modal" on:click={close}>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="times"
          class="svg-inline--fa fa-times fa-w-11"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 352 512"
          ><path
            fill="black"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
          /></svg
        >
      </div>
    {/if}
    {#if formitems.info != ""}
      <div class="textito">{formitems.info}</div>
    {/if}
    {#each formitems.items as item, i}
      {#if item.type == "text"}
        <div class="form-element">
          <input bind:value={item.val} type="text" placeholder={item.label} />
        </div>
      {:else if item.type == "date"}
        <div class="form-element">
          <input
            bind:value={item.val}
            type="text"
            onfocus="(this.type='date')"
            placeholder={item.label}
          />
        </div>
      {:else if item.type == "time"}
        <div class="form-element">
          <input
            bind:value={item.val}
            type="text"
            onfocus="(this.type='time')"
            placeholder={item.label}
          />
        </div>
      {:else if item.type == "select"}
        <div class="form-element">
          <div class="select">
            <select bind:value={item.val}>
              <option value="">--{item.label}--</option>
              {#each toArray(item.data) as data}
                <option value={data}>{data}</option>
              {/each}
            </select>
          </div>
        </div>
      {:else if item.type == "checkbox"}
        <div class="form-element">
          <div>
            <label style="width: auto;display: inline-block">
              <input
                style="width: auto;display: inline-block"
                value="Yes"
                bind:checked={item.val}
                type="checkbox"
              />
              {item.label}</label
            >
          </div>
        </div>
      {:else if item.type == "tel"}
        <div class="form-element">
          <TelField {data} bind:value={item.val} bind:telNum={item.telNum} placeholder={item.label} />
        </div>
      {:else if item.type == "email"}
        <div class="form-element">
          <input bind:value={item.val} type="email" placeholder={item.label} />
        </div>
      {/if}
      <FormValidate bind:value={errorList} bind:this={formValidateList[i]} {item}  {data} index={i}/>
    {/each}

    {#if formitems.buyLinks && formitems.buyLinks.length > 0}
      {#each formitems.buyLinks as item}
        <button
          class="buyButton"
          style="color:{data.textColor};background: {data.color}; border-color: {data.color}"
          on:click={() => sendtocallme(item.link)}
          type="button">{item.buttontext}</button
        >
      {/each}
    {:else}
      <div>
        <button
          style="color:{data.textColor};background: {data.color}; border-color: {data.color}"
          on:click={() => sendtocallme(null)}
          type="button">{formitems.buttontext}</button
        >
      </div>
    {/if}
  </div>
</div>

<style>
  .chatwith-box label,
  .chatwith-box select,
  .chatwith-box input {
    display: block;
    padding: 4px 0;
    font-size: 14px;
  }

  .chatwith-box input {
    padding: 8px 0;
  }

  .chatwith-box label {
    padding-bottom: 0 !important;
    font-weight: bold;
    margin: 0.75em 0 0.25em;
    color: #5c5b5b;
  }

  .chatwith-box select,
  .chatwith-box input,
  .chatwith-box button {
    border: 1px solid #ccc;
    padding: 5px 5px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    line-height: 1;
    height: auto;
  }

  .chatwith-box select {
    height: 35px;
  }

  .chatwith-box .form-element {
    margin-bottom: 10px;
  }

  .chatwith-box select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .chatwith-box .select {
    position: relative;
  }

  .chatwith-box .select::after {
    content: "v";
    position: absolute;
    top: 8px;
    right: 5px;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: #12b03f;
    font-family: Arial;
    text-align: center;
  }

  .chatwith-box button {
    border: 1px solid green;
    background: green;
    color: white;
    padding: 10px;
    cursor: pointer;
  }

  .chatwith-box {
    padding: 10px;
    box-sizing: border-box;
    background: white;
    color: #5c5b5b;
    width: 100%;
  }
  .chatwith-box .textito {
    font-weight: bold;
    padding-bottom: 5px;
    font-size: 14px;
    margin-top: -20px;
    width: 90%;
  }
  .close-modal {
    position: absolute;
    right: 0;
    z-index: 1000000;
    top: 0;
    padding: 4px 10px 0 10px;
    color: #fff;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 1em;
    cursor: pointer;
  }

  .close-modal svg {
    width: 0.7em;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #5c5b5b;
  }
  .chatwith-box ::-moz-placeholder {
    /* Firefox 19+ */
    color: #5c5b5b;
  }
  .chatwith-box :-ms-input-placeholder {
    /* IE 10+ */
    color: #5c5b5b;
  }
  .chatwith-box :-moz-placeholder {
    /* Firefox 18- */
    color: #5c5b5b;
  }

  .chatwith-box select,
  .chatwith-box input[type="email"],
  .chatwith-box input[type="text"],
  .chatwith-box input[type="date"],
  .chatwith-box input[type="time"] {
    color: #5c5b5b;
    height: 35px;
    margin-bottom: 0;
    font-weight: 0;
    font-weight: normal;
    font-family: Arial;
    font-size: 14px;
  }

  .buyButton:last-of-type {
    margin-bottom: 0;
  }
</style>
