<script>
    export let data;
    export let showall;

    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    function handleClick() {
        dispatch('close');
    }
</script>
{#if showall === true }
<div class="chatwith-header-small" style="background:{data.color};">    

    {#if data.hideHeaderLogo !== true }
    <div
            class="okewa-avatar {data.roundedLogo === true ? 'roundedLogo' : 'squareLogo'} "
            style="border-color:{data.color}"
    >
        <img
                class="profile-img"
                style="{!(data.iconWidth == 60 && data.iconHeight == 60) ? 'width:'+data.iconWidth+'px; height:'+data.iconHeight+'px':''}"
                alt="" src={data.iconUrl}
                onerror="this.src='process.env.MY_HOST'+'/bk.png'"/>
    </div>
    {/if}

    <div style="color:{data.textColor}" class="chatwith-intro">
        {data.widgetMessage}
    </div>

    <div on:click={handleClick} class="close-chatwith">
        <svg aria-hidden="true" focusable="false"  data-icon="times"  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#fff" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
    </div>
</div>
{:else}
<div class="chatwith-header" style="background:{data.color};">

    <div on:click={handleClick} class="close-chatwith">
        <svg aria-hidden="true" focusable="false"  data-icon="times"  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#fff" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
    </div>

    {#if data.hideHeaderLogo !== true }
    <div
            class="okewa-avatar {data.roundedLogo === true ? 'roundedLogo' : 'squareLogo'} "
            style="border-color:{data.color}"
    >
        <img
                class="profile-img"
                style="{!(data.iconWidth == 60 && data.iconHeight == 60) ? 'width:'+data.iconWidth+'px; height:'+data.iconHeight+'px':''}"
                alt="" src={data.iconUrl}
                onerror="this.src='process.env.MY_HOST'+'/bk.png'"/>
    </div>
    {/if}

    <div style="color:{data.textColor}" class="chatwith-intro">
        {data.widgetMessage}
    </div>

</div>
{/if}
<style>    

    .chatwith-header {
        position: relative;
        padding:20px 10px 5px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .chatwith-header .profile-img {
        display: block;
        width: 60px;
        height: 60px;
        margin: auto;
        border-radius: 200px;
        background: white;
        object-fit: cover;
    }

    .chatwith-header .close-chatwith{
        position: absolute;
        right:10px;
        top:10px;
        width: 25px;
        height: 25px;
    }

    .chatwith-header .close-chatwith svg{
        width: 25px;
        height: 25px;
    }

    .chatwith-header-small {
        position: relative;
        padding:10px 10px 5px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
    }

    .chatwith-header-small .profile-img {
        display: block;
        width: 30px;
        height: 30px;
        margin: auto;
        border-radius: 200px;
        background: white;
        object-fit: cover;
    }

    .chatwith-header-small .close-chatwith{      
        right:10px;
        top:10px;
        width: 15px;
        height: 15px;
    }

    .chatwith-header-small .close-chatwith svg{
        width: 15px;
        height: 15px;
    }

    .chatwith-header .squareLogo .profile-img ,
    .chatwith-header-small .squareLogo .profile-img {
        border-radius:0 !important;
    }


    .chatwith-header .chatwith-intro,
    .chatwith-header-small .chatwith-intro {
        text-align: center;
        color: white;
        font-size: 14px;
        width: 100%;
    } 

    @media (max-width: 767px) {
        .chatwith-header {
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            padding: .5em 2.25em .5em .5em;
        }
        

        .chatwith-header .profile-img {
            width: 36px;
            height: 36px;
            min-height: 36px;
            min-width: 36px;
        }

        .chatwith-header .close-chatwith{
            width: 18px;
            height: 18px;
        }

        .chatwith-header .close-chatwith svg {
            width: 18px;
            height: 18px;
        }

        .chatwith-header-small {
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            padding: .5em 2.25em .5em .5em;
        }
        

        .chatwith-header-small .profile-img {
            width: 30px;
            height: 30px;
            min-height: 30px;
            min-width: 30px;
        }

        .chatwith-header-small .close-chatwith{
            width: 15px;
            height: 15px;
        }

        .chatwith-header-small .close-chatwith svg {
            width: 15px;
            height: 15px;
        }

        .chatwith-header .chatwith-intro ,
        .chatwith-header-small .chatwith-intro{
            text-align: left;
            margin: .5em 0;
            font-size: 12px;
            width: 100%;

        }

        .okewa-avatar {
            margin-right: 1em;
        }
    }

</style>