{#if activeFaq === true }
<div  class="faq" aria-expanded={hidden}>
    <div style="color:{bgcolor}" class="chatwith-question" on:click={() => toggle()}>
        <div class="chatwith-question-text">{faq.question}</div>        
        <svg  width="15" height="15" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M9 5l7 7-7 7"></path>
		</svg>
    </div>
        {#if hidden === false || allowToggle===false}

            <div in:fade out:slide  class="chatwith-answer">
                {@html faq.answer}
            </div>
        {/if}
</div>
{/if}

<script>
    import { slide, fade } from 'svelte/transition';

    export let faq;
    export let bgcolor;
    export let activeFaq;
    export let allowToggle;

    let hidden=true

    const toggle = () => {
        if (allowToggle===true){
            hidden = !hidden
        }
    }
    bgcolor = "#263D62";

</script>

<style>
    .faq{
        padding-top:10px;
    }
    .chatwith-question {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 14px;
        display:flex;
        align-items: center;
        justify-content: flex-start;
    }
    /*[aria-expanded=true] .chatwith-question { border-bottom: #efefef 1px solid; }*/

    .chatwith-question-text {
        overflow-wrap:break-word;
		width:180px;
		min-width:180px;	
    }   
    .chatwith-answer {
        max-width: 240px;
        font-size: 14px !important;
        /*border-bottom: #efefef 1px solid;*/
    }
    .chatwith-answer a {
        text-decoration: underline;
        color: #999999;
    }
    .chatwith-answer p{
        margin:0
    }

    :global(.chatwith-answer p){
        margin-top:5px;
        margin-bottom: 5px;
    }

    svg { 
        transition: transform 0.2s ease-in;
        color: #01010161;
        margin-left:20px;
	}

    [aria-expanded=false] svg { transform: rotate(0.25turn); }
</style>
