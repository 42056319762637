{#if activeFaq === true }
<div  class="faq">
    <div style="color:{bgcolor}" class="chatwith-question" on:click={() => toggle()}>
        {faq.question}
    </div>
        {#if hidden === false || allowToggle===false}

            <div in:fade out:slide  class="chatwith-answer">
                {@html faq.answer}
            </div>
        {/if}
</div>
{/if}

<script>
    import { slide, fade } from 'svelte/transition';

    export let faq;
    export let bgcolor;
    export let activeFaq;
    export let allowToggle;

    let hidden=true

    const toggle = () => {
        if (allowToggle===true){
            hidden = !hidden
        }
    }


</script>

<style>
    .faq{
        padding-top:10px
    }
    .chatwith-question {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 14px;
    }
    .chatwith-answer {
        max-width: 240px;
        font-size: 14px !important;
    }
    .chatwith-answer a {
        text-decoration: underline;
        color: #999999;
    }
    .chatwith-answer p{
        margin:0
    }

    :global(.chatwith-answer p){
        margin-top:5px;
        margin-bottom: 5px;
    }
</style>
