<script>
    import Mainbutton from "../components/Mainbutton.svelte";
    import Online from "../components/Online.svelte";
    import Form from "../components/Form.svelte";
    import {send, sendform} from '../utils/sendwhatsapp';
    import {slide} from 'svelte/transition';
    import Modal from "../components/Modal.svelte";
    import {tick} from 'svelte';
    import Chat from "../components/chat/Chat.svelte";


    export let person;
    export let data;

    let showform = false
    let formitems = {items: []}
    let isOpen = false
    let isOpen2 = false

    let noclose = true

    let valueCopy = `<script defer data-widget-type="group" data-person="${person.id}"     src=\'/build/bundle.js?key=${data.id}\'><\/script><div id="whatapp-people-widget"><\/div>`;
    let areaDom;

    async function copy() {
        await tick();
        areaDom.focus();
        areaDom.select();
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                alert('Text copied to clipboard');
            }
        } catch (err) {
            console.log("could not copy to clipbloard")
        }
    }


    if (person.form) {
        formitems = person.form
    }

    function close() {
        showform = false
    }

    function sf(event) {
        sendform(person, event)
    }

    function handleClick(event) {
        if (person.form) {
            showform = true
        } else {
            send(person, event)
        }
    }
</script>

<div class="chatwith-person-big-person">

    <div class="singleperson {person.online === true?'active':'inactive'}">
        <div class="avatar">
            <div class="image-wrapper">
                <img class="avatar-pic" alt="" src={person.iconUrl}/>
            </div>
            <Online person="{person}"/>
        </div>
        <div class="persondata">

            {#if data.whatsapps.length == 1}
            <div style="padding:0 0 20px;font-size:14px">{data.widgetMessage}</div>
            {/if}


            {#if (person.post && person.post != "null" && person.post != null)  }
                <div class="post">{person.post}&nbsp;</div>
            {/if}
            <div class="name"><strong>{person.name}</strong></div>



            <Modal isOpen={isOpen}>
                <div style="font-size: 14px;margin: 10px;font-weight: bold">Copy and paste this code into your website
                </div>
                <textarea
                        on:click={copy}
                        bind:this={areaDom}
                        style="font-size: 14px;color: #999"
                        rows="7">
                    {valueCopy}
                </textarea>
            </Modal>


            <Modal isOpen={isOpen2}>
                <div style="font-weight: bold;font-size: 14px; padding-bottom:10px">I'm online from:</div>
                <table style="font-size: 14px">
                    {#each person.optimes as i}
                        <tr>
                            <td style="padding: 2px;text-align: left">{i.day}</td>
                            <td style="padding: 2px;text-align: left">{i.availableFrom.slice(0, -3)}</td>
                            <td style="padding: 2px;text-align: left"> {i.availableUntil.slice(0, -3)}</td>
                            <td style="color:#999;padding: 2px;text-align: left">
                                {#if i.timezone}{i.timezone}{/if}
                            </td>
                        </tr>
                    {/each}
                </table>
            </Modal>
        </div>
        {#if formitems.items.length == 0}

        <div class="widget-button-wrapper">
            <Mainbutton on:close={handleClick} data="{data}"/>
        </div>
        {/if}

        <div class="copy-widget-wrapper">
            <span on:click={() => isOpen=Math.random()}>
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="copy" class="svg-inline--fa fa-copy fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"></path></svg>
                Copy widget
            </span>
        </div>
        {#if person.optimes.length > 0}
        <div class="timetable">
            <span on:click={() => isOpen2=Math.random()}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-clock" class="svg-inline--fa fa-user-clock fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z"></path></svg>
                 Show timetable
            </span>
        </div>
        {/if}

        {#if formitems.items.length > 0}

            {#if person.chatform}
                <div style="width: 90%;background: #f7f7f7;margin: auto">
                    <div style="width: 350px; margin: 20px auto" >
                        <Chat {data} activePerson={person} on:close={() => {showform=false}}  />
                    </div>
                </div>
            {:else }
                <Form  on:callmex={sf} on:close={close} {data} {person} {formitems} {noclose}/>
            {/if}
        {/if}

</div>
</div>

<style>

    .flip-in-hor-bottom {
        -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }



    @-webkit-keyframes flip-in-hor-bottom {
        0% {
            -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
            opacity: 0;
        }
        100% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
            opacity: 1;
        }
    }

    @keyframes flip-in-hor-bottom {
        0% {
            -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
            opacity: 0;
        }
        100% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
            opacity: 1;
        }
    }

    .chatwith-person-big-person {
        position: relative;
        padding: 0 1em;
        box-sizing: border-box;
        margin-bottom: 2em;
        flex-grow: 1;
        width: 33%;
        display: flex;
        justify-content: center;
        font-family: Arial, sans-serif;

    }



    .chatwith-person-big-person .singleperson {
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0 ,0 ,0, .05);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,.05);
        box-shadow: 0px 0px 10px 0px rgba(0 ,0 ,0, .05);
        padding: 1.25em 1.25em 44px;
        border-radius: .5em;
        display: flex;
        max-width: 450px;
        align-items: center;
        flex-flow: wrap row;
        min-height: calc(100% - 4em);
        position: relative;
        height: 100%;
        background: #fff;

    }



    .chatwith-person-big-person .singleperson .avatar {
        width: 120px;
        position: relative;
        margin: 5px auto 0px;
    }

    .chatwith-person-big-person .singleperson .persondata {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
    }

    .chatwith-person-big-person .singleperson .persondata .name {
        font-size: 1.25em;
    }

    .chatwith-person-big-person .singleperson .avatar .image-wrapper {
        display: flex;
        width: 100px;
        height: 100px;
        overflow: hidden;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-flow: wrap column;
        border-radius: 50%;
        margin-bottom: .5em;
        border: 1px solid #eee;
    }

    .chatwith-person-big-person .singleperson .avatar .image-wrapper .avatar-pic {
        max-height: 100%;
    }

    .chatwith-person-big-person .singleperson.inactive .avatar .image-wrapper .avatar-pic {
        filter: grayscale(100%);
    }

    .chatwith-person-big-person .modal textarea {
        width: 100%;
    }

    .chatwith-person-big-person .widget-button-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: .5em 0 1.3em;
        background: transparent ;
    }

    .chatwith-person-big-person .copy-widget-wrapper {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: .5em .75em;
        border-top-left-radius: .75em;
        border-bottom-right-radius: .5em;
        -webkit-box-shadow: inset 1px 1px 3px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: inset 1px 1px 3px 0px rgba(0,0,0,0.05);
        box-shadow: inset 1px 1px 3px 0px rgba(0,0,0,0.05);
        background: #00bcbe
    }

    .chatwith-person-big-person .timetable {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        background-color: #aaa;
        padding: .5em .75em;
        border-bottom-right-radius: .5em;
        border-bottom-left-radius: .5em;
        right: 0;
        text-align: center;
    }

    .chatwith-person-big-person .timetable span,
    .chatwith-person-big-person .copy-widget-wrapper span {
        /*display: flex;
        align-items: center;*/
        font-size: .9em;
        cursor: pointer;

    }

    .chatwith-person-big-person .timetable svg,
    .chatwith-person-big-person .copy-widget-wrapper svg {
        width: 1em;
        margin-right: .25em;
    }

    .chatwith-person-big-person .modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        border: 0;
        width: 100%;
        height: 100%;
    }
    .chatwith-person-big-person .modal-content {
        -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.45);
        -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.45);
        box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.45);
        background: #fff;
        padding: 1.25em;
        border-radius: 1em;
        position: relative;
        background: #fff;
    }


    @media only screen and (min-width: 992px)  {
        .chatwith-person-big-person {

        }
    }
    @media only screen and (max-width: 991px)  {
        .chatwith-person-big-person {
            flex-grow: 1;
            width: 50%;
        }
    }
    @media only screen and (max-width: 576px)  {
        .chatwith-person-big-person {
            flex-grow: 1;
            width: 100%;
        }
    }

</style>
