<script>

    export let isOpen

    let open = 0

    $: if (isOpen > 0) {
        open = true
    }

</script>

{#if open}
<div class="modal">
    <div class="modal-content">
        <div class="close-modal" on:click="{() => open = false}">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#000" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
        </div>
        <div >
            <slot></slot>
        </div>
    </div>
</div>
{/if}

<style>



    .flip-in-hor-bottom {
        -webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes flip-in-hor-bottom {
        0% {
            -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
            opacity: 0;
        }
        100% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
            opacity: 1;
        }
    }
    @keyframes flip-in-hor-bottom {
        0% {
            -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
            opacity: 0;
        }
        100% {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
            opacity: 1;
        }
    }



    .modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        border: 0;
        width: 100%;
        height: 100%;
    }
    .modal-content {
        -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.45);
        -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.45);
        box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.45);
        background: #fff;
        padding: 1.25em;
        border-radius: 1em;
        position: relative;
    }

    .modal-content textarea {
        width: 100%
    }

    .close-modal {
        position: absolute;
        right: 0;
        z-index: 1000000;
        top: 0;
        padding: 4px 10px 0 10px;
        color: #fff;
        border-top-right-radius: 1em;
        border-bottom-left-radius: 1em;
        cursor: pointer;
    }

    .close-modal svg {
        width: .7em;
    }


</style>
