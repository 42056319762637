<script>
    import {createEventDispatcher} from "svelte";
    import { onMount } from 'svelte';
    import ChatTextBox from "./ChatTextBox.svelte";
    import ChatCheckBox from "./ChatCheckBox.svelte";
    import ChatSelect from "./ChatSelect.svelte";
    import ChatBubbleBot from "./ChatBubbleBot.svelte";
    import ChatBubbleUser from "./ChatBubbleUser.svelte";
    import TelField from '../TelField.svelte';
    import { sendform } from "../../utils/sendwhatsapp"

    export let data;
    export let activePerson;

    let sent = false
    let err = false;
    let index = 0;

    $: formitems = activePerson.form.items
    $: type = "text"

    onMount(()=>{
        type = formitems[0].type;
    });

    const dispatch = createEventDispatcher();

    function handleClick() {
        dispatch('close');
    }

    const showBot = (i, bubble) => {
        if (i == 0){
            return true
        }
        else if (formitems[i-1].val){
            return true
        }
        return false
    }

    const showUser = (i, bubble) => {
        if (formitems[i].val){
            return true
        }
        return false
    }

    const setVal = (v) => {
        index = formitems.findIndex(obj => !obj.val)
        let val;

        if(type == 'tel'){
            val = v.detail.num === '' ? '' : '+' + v.detail.code + '-' + v.detail.num;
        }else {
            val = v.detail.trim();
        }


        if(!validate(type,type === 'tel'?v.detail.num:v.detail.trim(),activePerson.form.items[index].required))
            return false;

        formitems[index].val = val === '' ? ' ' : val;

        if (!isLastSend(index, formitems)){
            type = formitems[index+1].type
        }
        else{
            sent = true
            sendform(activePerson,{detail:{data: formitems}})
        }

        index++;
    }

    const isLastSend = (i, payload) => {
        if(formitems.length - 1 == i) {
            return true
        }
        return false
    }

    const validate = (type, value, isRequired) => {
        if(value ===''){
            if(!isRequired)
                return true;
            err = data.requiredValidationText?data.requiredValidationText:'This needs to be filled in.';
            return false;
        }
        if(type === 'email'){
            if( !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){
                err = data.emailValidationText?data.emailValidationText:'Sorry but this email is not valid.';
                return false;
            }
        }else if(type === 'tel'){
            if( !/^\d{6,15}$/.test(value)){
                err = data.telValidationText?data.telValidationText:'This is not a phone number.';
                return false;
            }
        }
        err= false;
        return true;
    }
</script>

<div  class="whataapp-chat-panel">

    <div on:click={handleClick} class="close-chatwith">
        <svg style="width: 12px" aria-hidden="true" focusable="false"  data-icon="times"  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="#333" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
    </div>

    <div  class="whatapp-messages">
    {#each formitems as bubble, i (bubble.label)}
        {#if showBot(i, bubble) === true }
            <div class="scale-in-left">
                <div class="whatapp-msg-header">{activePerson.name}</div>
            <ChatBubbleBot   text={bubble.label} />
            </div>
        {/if}
        {#if showUser(i, bubble) === true }
            <div class="scale-in-right">
            <ChatBubbleUser   bubbleData={bubble} />
            </div>
        {/if}
    {/each}
    </div>


    <div class="whatapp-fix-to-bottom">
    {#if sent === true}
        <div class="whataapp-sent-message">
            Your message has been sent
        </div>
    {:else}
        {#if err}<div style="color:darkred;font-size:14px">{err}</div>{/if}

        {#if type == 'checkbox' }
            <ChatCheckBox on:message={setVal}/>
        {:else if type === 'select'}
            <ChatSelect on:message={setVal} options={formitems[index]}/>
        {:else if type === 'tel'}
            <TelField on:submit={setVal} {data} showButton={true}  class="whataapp-telfield"/>
        {:else}
            <ChatTextBox on:message={setVal} />
         {/if}
    {/if}
    </div>

</div>

<style>
    .whataapp-chat-panel{
        min-height: 200px;
        padding-top: 5px;
        background: #f7f7f7;
        position: relative;
        width: 100%;
    }

    .whataapp-sent-message{
        text-align: center;
        padding:20px;
        background: rgb(39, 217, 116);
        color: white;
    ;
    }

    .whatapp-fix-to-bottom{
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        background: #f7f7f7;
    }

    .whatapp-messages{
        /* margin-bottom: 40px; */
        padding-bottom: 60px;
    }


    :global(.whataapp-telfield){
        margin-bottom:20px;
    }

    .scale-in-left {
        -webkit-animation: scale-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-delay: 1s;
    }

    /* ----------------------------------------------
 * Generated by Animista on 2021-9-9 18:4:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation scale-in-left
     * ----------------------------------------
     */
    @-webkit-keyframes scale-in-left {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            opacity: 1;
        }
    }
    @keyframes scale-in-left {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            opacity: 1;
        }
    }

    .whatapp-msg-header {
        font-size: 12px;
        line-height: 1.42;
        padding-inline-start: 5px;
    }


    .scale-in-right {
        -webkit-animation: scale-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /* ----------------------------------------------
 * Generated by Animista on 2021-9-9 18:5:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation scale-in-right
     * ----------------------------------------
     */
    @-webkit-keyframes scale-in-right {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            opacity: 1;
        }
    }
    @keyframes scale-in-right {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            opacity: 1;
        }
    }

    .close-chatwith{
        position: relative;
        z-index: 100;
    }

    .close-chatwith svg{
        position: absolute;
        right:12px;top:0px
    }

</style>
